<template>
	<div class="redaction-dashboard-layout">
		<v-row
			class="mt-1 fullprofilecontainer ml-0 mr-0"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<v-col md="3" cols="12">
				<v-col md="12" cols="12">
					<div class="text-center" v-if="user">
						<OpenProfileModal
							:profileData="user"
							:formationData="formationProgress.formation"
							:disableProfileModal="true"
						/>
						<h2>{{ user.first_name }} {{ user.last_name }}</h2>
						<!-- Redaction dont have strike now -->
						<!-- <RedactionStrike v-if="user" :user="user" /> -->
					</div>
				</v-col>
				<!-- if mobile display exercice button on top -->
				<v-col md="12" cols="12" class="text-center pr-0 pl-0" v-if="!$vuetify.breakpoint.mdAndUp">
					<TodaySession v-if="formationProgress" :formationProgress="formationProgress" />
				</v-col>

				<!-- Weekly leaderboard block - Mobile -->
				<!-- Redaction doesn't have leaderboard now -->
				<!-- End Weekly leaderboard block - Mobile -->

				<!-- List Parcours Block -->
				<div id="ParcoursBlock" v-if="this.formationProgress">
					<v-col
						id="ParcoursBlockMobile"
						v-if="$vuetify.breakpoint.mdAndUp == false"
						:class="{ 'd-none': currentRouteName === 'Category Detail' }"
						cols="12"
						class="shadow-blue-not-openned mb20"
					>
						<ListParcoursSession :formationProgress="formationProgress" />
					</v-col>
					<v-col
						id="ParcoursBlockDesktop"
						v-if="$vuetify.breakpoint.smAndDown == false"
						cols="12"
						class="shadow-blue-not-openned mb20"
						style="border-radius: 5px !important"
					>
						<ListParcoursSession :formationProgress="formationProgress" />
					</v-col>
				</div>
				<ListParcoursSkeleton v-else></ListParcoursSkeleton>
				<!-- End List Parcours Block -->

				<v-alert
					v-if="
						user &&
						user.entreprise_id &&
						user.entreprise_id._id == '5f6c994eac759a00173cef92' &&
						checkIfEndDateApprochingTenDaysEnd() === true
					"
					border="left"
					type="info"
					color="#8186e8"
					style="display: block"
				>
					<v-chip class="mb-2" color="#686bdf" text-color="white">
						<div class="ortho-button-exercice-container">
							<img src="@/assets/icons/chrono.png" alt="chrono" class="speakerEmoji" />
							<span><b>À court de temps ?</b></span>
						</div>
					</v-chip>
					<h3 class="mb-2">Gagne 1 mois de formation en plus !</h3>
					<a
						href="https://orthographiq.webflow.io/parrainez-vos-proches-pour-orthographiq?utm_source=app&utm_medium=dashboard-side-section&utm_campaign=parrainage"
						target="_blank"
						class="fs12 ortho-bold white--text"
						@click="goToReferralPage()"
						>PARRAINER MES PROCHES<v-icon>mdi-menu-right</v-icon></a
					>
				</v-alert>

				<v-col cols="12" class="profileManagementContainer" :class="{ 'd-none': $vuetify.breakpoint.smAndDown }">
					<ProfileManagement v-if="user" :profileData="user" />
				</v-col>
			</v-col>

			<v-col md="6" cols="12">
				<router-view></router-view>
			</v-col>

			<v-col md="3" cols="12">
				<div>
					<TodaySession
						v-if="formationProgress && !$vuetify.breakpoint.smAndDown"
						:formationProgress="formationProgress"
					/>
				</div>

				<v-col cols="12" :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }">
					<ProfileManagement v-if="user" :profileData="user" />
				</v-col>
			</v-col>
		</v-row>
		<AlertChoiceCategorySession
			v-if="
				!isSwitchFormationStateLoading &&
				formationProgress &&
				!formationProgress.formation.last_session_id &&
				!formationProgress.formation.last_category &&
				formationProgress.formation.formation_finished !== true &&
				formationProgress.formation.last_exercice_date
			"
			:formationProgress="formationProgress"
		/>
	</div>
</template>

<script>
import OpenProfileModal from '@/components/dashboard/profile/profileModal/OpenProfileModal.vue';
import ProfileManagement from '@/components/dashboard/profile/ProfileManagement.vue';
import TodaySession from '@/components/redaction/TodaySession.vue';
// import RedactionStrike from '@/components/redaction/RedactionStrike.vue';
import ListParcoursSkeleton from '@/components/skeletons/ListParcoursSkeleton.vue';
import ListParcoursSession from '@/components/dashboard/profile/ListParcoursSession.vue';
import AlertChoiceCategorySession from '@/components/dashboard/profile/AlertChoiceCategorySession.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'RedactionDashboardLayout',
	components: {
		OpenProfileModal,
		ProfileManagement,
		TodaySession,
		ListParcoursSkeleton,
		ListParcoursSession,
		AlertChoiceCategorySession,
		// RedactionStrike,
	},

	data() {
		return {
			formationProgress: null,
			autoStartTourProfile: true,
			currentRouteName: '',
			autostartConfig: {
				nextLabel: 'Suivant',
				prevLabel: 'Retour',
				skipLabel: 'Sortir',
				doneLabel: 'Terminé',
				showStepNumbers: false,
			},
			user: null,
		};
	},
	computed: {
		...mapGetters('appState', {
			isSwitchFormationStateLoading: 'isSwitchFormationStateLoading',
		}),
	},
	methods: {
		onFinishTourProfile() {
			localStorage.setItem('tour-profile', 'profile');
		},
		checkIfEndDateApprochingTenDaysEnd() {
			const todayDate = new Date();
			const endDate = new Date(this.formationProgress.formation.end_date);
			const endDateMinusOneMonth = endDate.setDate(endDate.getDate() - 10);

			if (todayDate >= endDateMinusOneMonth) return true;
			else return false;
		},
		goToReferralPage() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-profile-goToReferralPage');
		},
	},
	async mounted() {
		this.currentRouteName = this.$router.currentRoute.name;
		const { formationId } = this.$route.params;
		const formationProgress = await this.$store.dispatch('profile/formationProgress', { idFormation: formationId });
		if (formationProgress.error) return;
		this.formationProgress = formationProgress;

		const user = await this.$store.dispatch('accountManager/getUserWihoutFormation', {
			idUser: formationProgress.formation.user_id,
		});
		this.user = user;

		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('dashboard-redaction-profile');
		}
	},
};
</script>

<style scoped lang="scss">
.redaction-dashboard-layout {
	#programmes {
		border-radius: 4px;
	}
	.unlockIcon {
		padding-right: 10px;
	}
	.lightIconGlobal {
		margin-left: -6px;
		height: 15px;
	}
	.lightIcon {
		height: 12px;
	}
	.legend-container {
		margin-left: 10px;
	}
	.kpiIcons {
		width: 13%;
	}
	.imageifnoexercice {
		width: 100%;
	}
	.image-finish-formation {
		width: 75%;
	}

	.speakerEmoji {
		width: 20px;
		margin-right: 5px;
	}

	@media only screen and (max-width: 1500px) {
		.fullprofilecontainer {
			padding-left: 3%;
			padding-right: 3%;
		}
	}
}
::v-deep {
	.v-alert__icon.v-icon {
		margin-top: 16px;
	}
}
</style>
