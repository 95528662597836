<template>
	<div v-if="streak" class="streak-in-statistic">
		<div class="pictureDiv">
			<img src="@/assets/icons/emoji/gif-flame.gif" alt="flame" class="flame-icon" />
		</div>
		<div class="numberDiv">
			<div class="titleNumber">{{ streak.highest_streak ? streak.highest_streak : 0 }}</div>
			<div class="subTitleNumber">
				<span>meilleure série</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'StreakInProfileStatistics',
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		...mapGetters('streak', { streak: 'streak' }),
	},
	async created() {
		if (this.streak == null && this.profileInfos._id) {
			this.$store.dispatch('streak/searchStreakByUserId', { userId: this.profileInfos._id });
		}
	},
};
</script>

<style lang="scss" scoped>
.streak-in-statistic {
	display: flex;
	flex-direction: row;
	justify-content: left;

	.pictureDiv {
		margin-right: 10px;
		display: flex;
		align-items: center;

		.flame-icon {
			margin-top: -25px;
			width: 30px;
			height: 30px;
			object-fit: contain;
		}
	}

	.numberDiv {
		text-align: left;

		.titleNumber {
			font-size: 30px;
			font-weight: 700;
		}

		.subTitleNumber {
			color: grey;
		}
	}
}

@media screen and (max-width: 600px) {
	.streak-in-statistic {
		width: 100% !important;
	}
}
</style>
