<template>
	<div>
		<CategoryPointProgressBar
			class="mb-9"
			:globalScoreRules="globalScoreRules"
			:globalTargetScoreRules="1500"
			:loading="loadingCategoryReport"
		/>
		<div class="pb-3 mb-9">
			<ChapterDetailHeader :selectingCategory="selectingCategory" />
		</div>
		<ListExercices v-if="selectingCategory" :exercises="selectingCategory.exercises" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CategoryPointProgressBar from '@/components/progress-bar/CategoryPointProgressBar.vue';
import ListExercices from '../exercise/ListExercices';
import ChapterDetailHeader from './ChapterDetailHeader';

export default {
	name: 'ChapterDetail',
	components: {
		ChapterDetailHeader,
		ListExercices,
		CategoryPointProgressBar,
	},
	data() {
		return {
			selectingCategory: null,
			loadingCategoryReport: true,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		...mapGetters('reportCategory', {
			globalScoreRules: 'globalScoreRules',
			globalTargetScoreRules: 'globalTargetScoreRules',
		}),
	},
	mounted() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	methods: {
		async getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null) return null;

			this.fetchCategoryReport(this.formationProgress.formation._id, this.$route.params.idCategory);

			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.getCategoryInParcours(currentParcoursItem.parcours_id, this.$route.params.idCategory);
		},
		getCategoryInParcours(parcours, idCategory) {
			const selectingCategory = parcours.categories.find((category) => category._id === idCategory);
			this.selectingCategory = selectingCategory;
		},
		async fetchCategoryReport(idFormation, idCategory) {
			this.loadingCategoryReport = true;
			if (idFormation == null || idCategory == null) return;
			await this.$store.dispatch('reportCategory/getCategoryReport', { idFormation, idCategory });
			this.loadingCategoryReport = false;
		},
	},
};
</script>

<style lang="scss" scoped></style>
