<template>
	<div></div>
</template>

<script>
import axios from 'axios';
import { getToken, getMessaging } from 'firebase/messaging';
import { PUBLIC_VAPID_KEY } from '@/config/firebase-messaging.js';

export default {
	name: 'Logout',
	async created() {
		await this.unregisterToken();
		localStorage.removeItem('token');
		this.$router.push('/login');
	},
	methods: {
		async unregisterToken() {
			const messaging = getMessaging();

			const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });
			if (token) {
				await axios.post('/api/notifications/unregister-token', { token });
			}
		},
	},
};
</script>
