<template>
	<div class="test-question">
		<div class="ortho-bold test-position-question">
			<span>{{ testTrack.question }}</span>
		</div>
		<div v-if="testTrack && testTrack.instruction" class="test-position-action-container">
			<p>{{ testTrack.instruction }}</p>
		</div>
		<div class="test-position-answers-container">
			<FadeTransition group>
				<div
					class="hvr-bounce-in test-position-answers"
					v-for="(answer, index) in testTrack.answers"
					:key="index"
					@click="onClickAnswer(answer)"
				>
					{{ answer.answer }}<br />
				</div>
			</FadeTransition>
		</div>
	</div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';

export default {
	name: 'QuestionQCM',
	props: { testTrack: Object, onClickAnswer: Function },
	components: {
		FadeTransition,
	},
};
</script>

<style lang="scss" scoped>
// Style in styles.css
</style>
