<template>
	<div>
		<h2 class="fs25">
			Progression
			<v-tooltip color="black" right>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
					</v-btn>
				</template>
				<span v-if="totalParcoursExercise">
					{{
						`${totalExerciseDone}/${totalParcoursExercise} exercice${totalExerciseDone > 1 ? 's' : ''} fait${
							totalExerciseDone > 1 ? 's' : ''
						}`
					}}
				</span>
			</v-tooltip>
		</h2>
		<div v-if="selectingParcours == null" class="min-w-100 mt-3">
			<v-progress-linear indeterminate color="#686bdf" class="border-radius" rounded height="15"></v-progress-linear>
		</div>
		<div v-else class="parcours-progress-bar d-flex mt-3">
			<div
				class="category"
				v-for="(item, index) in progressData"
				:key="index"
				:class="{ 'last-item': index === progressData.length - 1 }"
			>
				<!-- First item -->
				<v-progress-linear
					v-if="index === 0"
					class="border-radius no-right-border-radius"
					:value="item.completePercent"
					color="#686bdf"
					height="12"
					rounded
					background-color="#F1F1F1"
				/>
				<!-- Last item -->
				<template v-else-if="index === progressData.length - 1">
					<v-progress-linear :value="item.completePercent" color="#686bdf" height="12" background-color="#F1F1F1" />
					<div class="img-container" :class="{ 'completed-bg': item.completePercent === 100 }">
						<img class="trophy" src="@/assets/images/goToReport/end-parcours.png" />
					</div>
				</template>
				<!-- Middle item -->
				<v-progress-linear
					v-else
					:value="item.completePercent"
					color="#686bdf"
					height="12"
					background-color="#F1F1F1"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { ExerciseStatus } from '@/constants/exercise.js';

export default {
	name: 'ParcoursProgressBar',
	props: {
		selectingParcours: Object,
	},
	data() {
		return {
			progressData: [],
			totalParcoursExercise: null,
			totalExerciseDone: null,
		};
	},
	mounted() {
		this.genData();
	},
	methods: {
		genData() {
			if (this.selectingParcours == null) return;
			const tempArr = [];

			this.selectingParcours.categories.forEach((cat) => {
				const countExercicesDoneOfThisCat = this.countExerciseDone(cat.exercises);
				const totalExercicesOfThisCat = cat.exercises.length;
				const completePercent = (countExercicesDoneOfThisCat / totalExercicesOfThisCat) * 100;
				tempArr.push({ categoryId: cat._id, completePercent });

				if (this.totalParcoursExercise == null) this.totalParcoursExercise = totalExercicesOfThisCat;
				else this.totalParcoursExercise += totalExercicesOfThisCat;

				if (this.totalExerciseDone == null) this.totalExerciseDone = countExercicesDoneOfThisCat;
				else this.totalExerciseDone += countExercicesDoneOfThisCat;
			});

			tempArr.sort((a, b) => b.completePercent - a.completePercent);

			this.progressData = tempArr;
		},
		countExerciseDone(exercisesArrayWithStatus) {
			return exercisesArrayWithStatus.filter((exercise) => exercise.status === ExerciseStatus.PASSED).length;
		},
	},
	watch: {
		selectingParcours() {
			this.totalParcoursExercise = null;
			this.totalExerciseDone = null;
			this.genData();
		},
	},
};
</script>

<style lang="scss" scoped>
.border-radius {
	border-radius: 1rem;
}

.parcours-progress-bar {
	.category {
		min-width: calc(100% / 8);
		margin-right: 7px;
	}

	.no-right-border-radius {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.last-item {
		position: relative;
		.img-container {
			position: absolute;
			top: -11px;
			right: -2px;
			background-color: #f1f1f1;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.img-container.completed-bg {
			background-color: #686bdf;
		}
		.trophy {
			width: 30px;
		}
	}
}

@media screen and (max-width: 500px) {
	.parcours-progress-bar {
		.category {
			margin-right: 2px;
		}

		.last-item {
			.img-container {
				top: -6px;
				right: -17px;
				width: 27px;
				height: 27px;
			}

			.trophy {
				width: 25px;
			}
		}
	}
}
</style>
