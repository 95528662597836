<template>
	<div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMessaging, getToken } from 'firebase/messaging';
import { PUBLIC_VAPID_KEY } from '@/config/firebase-messaging.js';

export default {
	name: 'WebPushNotificationPermission',
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
	},
	methods: {
		async askForPermission() {
			try {
				const permission = await Notification.requestPermission();

				if (permission === 'granted') {
					const messaging = getMessaging();
					const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });

					await this.$store.dispatch('profile/turnOnPushNotification', { newToken: token });
				}
			} catch (error) {
				console.warn(error);
			}
		},
	},
	watch: {
		profileInfos() {
			if (localStorage.getItem('token') != null && this.profileInfos.allow_push_notification === true) {
				this.askForPermission();
			}
		},
	},
};
</script>

<style></style>
