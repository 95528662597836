<template>
	<div>
		<h2>Progression</h2>
		<div class="mx-auto">
			<CategorySessionPointProgressBar :totalScore="categoryTotalPoints" :score="categoryPoints" />
		</div>
	</div>
</template>

<script>
import CategorySessionPointProgressBar from '@/components/progress-bar/CategorySessionPointProgressBar.vue';

export default {
	name: 'ChapterSessionProgressionBlock',
	props: {
		category: Object, // Category from formation progress (has sessions and session has score)
	},
	components: {
		CategorySessionPointProgressBar,
	},
	computed: {
		categoryTotalPoints() {
			if (!this.category) return 0;

			return this.category.sessions.length * 1000;
		},
		categoryPoints() {
			if (!this.category) return 0;
			let totalScore = 0;
			this.category.sessions.forEach((session) => {
				if (session.score != null) {
					totalScore += session.score * 10;
				}
			});

			return totalScore;
		},
	},
};
</script>

<style lang="scss" scoped></style>
