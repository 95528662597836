<template>
	<div>
		<div>
			<DotLoadingPage v-if="isRendering" />
			<v-row
				v-else
				class="mt-1 fullprofilecontainer ml-0 mr-0"
				:class="{
					palr5: $vuetify.breakpoint.smAndDown,
					palr10: $vuetify.breakpoint.mdAndUp,
				}"
				v-intro-autostart="autoStartTourProfile"
				v-intro-autostart.config="autostartConfig"
				v-intro-autostart:on.complete="onFinishTourProfile"
				v-intro-autostart:on.exit="onFinishTourProfile"
			>
				<v-col md="3" cols="12">
					<v-col md="12" cols="12">
						<div class="text-center">
							<OpenProfileModal :profileData="profileData" :formationData="formationData" />
							<h2>{{ profileData.first_name }} {{ profileData.last_name }}</h2>
							<StreakOnDashboard class="mt-2 mb-2" />
						</div>
					</v-col>
					<!-- if mobile display exercice button on top -->
					<v-col
						v-if="$vuetify.breakpoint.smAndDown"
						md="12"
						cols="12"
						class="text-center pr-0 pl-0"
						:class="{ 'd-none': $vuetify.breakpoint.mdAndUp, 'd-none': currentRouteName != 'Category Detail' }"
					>
						<TodayExercice :formationData="formationData" :exercicesDisplay="exercicesDisplay" />
					</v-col>

					<!-- Weekly leaderboard block - Mobile -->
					<v-col
						v-if="$vuetify.breakpoint.smAndDown && !hideLeaderboardBlock"
						cols="12"
						:class="{ 'd-none': currentRouteName != 'Category Detail' }"
						class="pr-0 pl-0 pt-0 mb20"
					>
						<LeaderBoardBlock class="px-3 py-3 shadow-blue-not-openned ortho-card-border-radius-4" />
					</v-col>
					<!-- End Weekly leaderboard block - Mobile -->

					<!-- List Parcours Block -->
					<div id="ParcoursBlock" v-if="this.formationProgress">
						<v-col
							id="ParcoursBlockMobile"
							v-if="$vuetify.breakpoint.mdAndUp == false"
							:class="{ 'd-none': currentRouteName === 'Category Detail' }"
							cols="12"
							class="shadow-blue-not-openned mb20"
							v-intro="
								'<h2>Tes programmes</h2>OrthographIQ c\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'
							"
							v-intro-tooltip-class="'custom-tooltip-introjs'"
						>
							<ListParcours :formationProgress="formationProgress" />
						</v-col>
						<v-col
							id="ParcoursBlockDesktop"
							v-if="$vuetify.breakpoint.smAndDown == false"
							cols="12"
							class="shadow-blue-not-openned mb20"
							v-intro="
								'<h2>Tes programmes</h2>OrthographIQ c\'est 8 programmes pour perfectionner tes écrits. </br></br>Tu les verras apparaître au fur et à mesure de ton avancée.'
							"
							v-intro-tooltip-class="'custom-tooltip-introjs'"
							style="border-radius: 5px !important"
						>
							<ListParcours :formationProgress="formationProgress" />
						</v-col>
					</div>
					<ListParcoursSkeleton v-else></ListParcoursSkeleton>
					<!-- End List Parcours Block -->

					<v-alert
						v-if="
							profileData.entreprise_id &&
							profileData.entreprise_id._id == '5f6c994eac759a00173cef92' &&
							checkIfEndDateApprochingTenDaysEnd() == true
						"
						border="left"
						type="info"
						color="#8186e8"
						style="display: block"
					>
						<v-chip class="mb-2" color="#686bdf" text-color="white">
							<div class="ortho-button-exercice-container">
								<img src="@/assets/icons/chrono.png" alt="chrono" class="speakerEmoji" />
								<span class="font-weight-bold">À court de temps ?</span>
							</div>
						</v-chip>
						<h3 class="mb-2">Gagne 1 mois de formation en plus !</h3>
						<a
							href="https://orthographiq.webflow.io/parrainez-vos-proches-pour-orthographiq?utm_source=app&utm_medium=dashboard-side-section&utm_campaign=parrainage"
							target="_blank"
							class="fs12 ortho-bold white--text"
							@click="goToReferralPage()"
							>PARRAINER MES PROCHES<v-icon>mdi-menu-right</v-icon></a
						>
					</v-alert>

					<v-col cols="12" class="profileManagementContainer" :class="{ 'd-none': $vuetify.breakpoint.smAndDown }">
						<ProfileManagement :profileData="profileData" />
					</v-col>
				</v-col>

				<v-col md="6" cols="12">
					<router-view></router-view>
				</v-col>

				<v-col md="3" cols="12">
					<div v-if="exercicesDisplay.length >= 0">
						<TodayExercice
							:class="{ 'd-none': $vuetify.breakpoint.smAndDown }"
							:formationData="formationData"
							:exercicesDisplay="exercicesDisplay"
						/>
					</div>
					<!-- Weekly leaderboard block - Desktop -->
					<v-col
						v-if="$vuetify.breakpoint.mdAndUp && !hideLeaderboardBlock"
						cols="12"
						class="shadow-blue-not-openned ortho-card-border-radius-4 mb20"
					>
						<LeaderBoardBlock />
					</v-col>
					<!-- End Weekly leaderboard block - Desktop -->
					<RulesProfile
						v-if="
							$vuetify.breakpoint.smAndDown &&
							formationData &&
							formationData.last_exercice_date &&
							rulesUser &&
							rulesUser.length > 0
						"
						:rulesUser="rulesUser"
						:formationData="formationData"
						:class="{ 'd-none': currentRouteName != 'Category Detail' }"
						v-intro="
							'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'
						"
						v-intro-tooltip-class="'custom-tooltip-introjs'"
					/>

					<RulesProfile
						v-if="
							$vuetify.breakpoint.mdAndDown == false &&
							formationData &&
							formationData.last_exercice_date &&
							rulesUser &&
							rulesUser.length > 0
						"
						:rulesUser="rulesUser"
						:formationData="formationData"
						v-intro="
							'<h2>Collectionne les règles et maîtrise-les !</h2>Les points gagnés lors des sessions quotidiennes te permettent de compléter les jauges des règles pour les maîtriser.'
						"
						v-intro-tooltip-class="'custom-tooltip-introjs'"
					/>
					<v-col cols="12" :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }">
						<ProfileManagement :profileData="profileData" />
					</v-col>
				</v-col>
			</v-row>
			<AlertChoiceCategory
				:listCategories="listCategories"
				:listCategoriesDone="categoriesDone"
				:formationId="formationData._id"
				v-if="
					!isSwitchFormationStateLoading &&
					!formationData.last_exercice &&
					!formationData.last_category &&
					formationData.formation_finished != true &&
					formationData.last_exercice_date
				"
			/>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import RulesProfile from './profile/Rules';
import ListParcours from './profile/ListParcours';
import TodayExercice from './profile/TodayExercice';
import ProfileManagement from './profile/ProfileManagement';
import AlertChoiceCategory from './profile/AlertChoiceCategory';
import ListParcoursSkeleton from '../skeletons/ListParcoursSkeleton';
import LeaderBoardBlock from '@/components/dashboard/profile/leaderBoardBlock/LeaderBoardBlock';
import { checkDateWithinThisWeek } from '@/utils/time.js';
import OpenProfileModal from './profile/profileModal/OpenProfileModal';
import DotLoadingPage from '../dot-loading-page/DotLoadingPage.vue';
import StreakOnDashboard from '@/components/streak/StreakOnDashboard.vue';

// import { OnBoarding } from "@/assets/js/dashboard-onboarding.js";

export default {
	name: 'Profile',
	components: {
		DotLoadingPage,
		RulesProfile,
		ListParcours,
		TodayExercice,
		ProfileManagement,
		AlertChoiceCategory,
		ListParcoursSkeleton,
		LeaderBoardBlock,
		OpenProfileModal,
		StreakOnDashboard,
	},
	props: {
		profileData: Object,
		formationData: Object,
		exercicesDoneData: Array,
		listCategories: Array,
		categoriesDone: Array,
	},
	data() {
		return {
			isRendering: true,
			exercicesDisplay: [],
			autoStartTourProfile: false,
			currentRouteName: '',
			autostartConfig: {
				nextLabel: 'Suivant',
				prevLabel: 'Retour',
				skipLabel: 'Sortir',
				doneLabel: 'Terminé',
				showStepNumbers: false,
			},
		};
	},
	mounted() {
		this.currentRouteName = this.$router.currentRoute.name;
		//for mobile : always display top of the page
		window.scrollTo(0, 0);
		if (localStorage.getItem('tour-profile') == 'profile') {
			this.autoStartTourProfile = false;
		}

		// The loading on this page is managed manually with a set duration. The status isSwitchFormationStateLoading allows us to check if another manual loading process is in progress.

		const duration = 5000;
		setTimeout(
			() => {
				this.isRendering = false;
			},
			this.isSwitchFormationStateLoading ? 0 : duration
		);
	},
	created() {
		setTimeout(() => {
			if (Object.keys(this.formationData).length != 0) {
				this.$store.dispatch('profile/formationProgress', {
					idFormation: this.formationData._id,
				});
				if (this.formationData.last_category) {
					this.exercicesDisplay = this.exercicesDoneData.filter((exercice) => {
						return exercice.exercices_id.cat_id._id == this.formationData.last_category._id;
					});
				}
				this.$store.dispatch('profile/getRules', {
					idFormation: this.formationData._id,
				});
				this.$store.dispatch('profile/getStrike', {
					idFormation: this.formationData._id,
				});
				if (this.formationData.parcours_id) {
					this.$store.dispatch('statuts/getStatutsParcours', {
						idParcours: this.formationData.parcours_id,
					});
				}
			}
		}, 3000);
	},
	computed: {
		...mapGetters('profile', {
			rulesUser: 'rulesUser',
			formationProgress: 'formationProgress',
		}),
		...mapGetters('appState', {
			isSwitchFormationStateLoading: 'isSwitchFormationStateLoading',
		}),
		dateDisplay() {
			if (moment().format('e') == 5 || moment().format('e') == 6) return 'lundi';
			else return 'demain';
		},
		hideLeaderboardBlock() {
			if (this.formationProgress == null) return true;
			const { formation } = this.formationProgress;
			if (formation.formation_finished && !checkDateWithinThisWeek(formation.last_exercice_date)) return true;

			return false;
		},
	},
	watch: {
		'$route.params'() {
			this.currentRouteName = this.$router.currentRoute.name;
		},
	},
	methods: {
		didHeSucceed() {
			for (var i = 0; i < this.exercicesDisplay.length; i++) {
				if (this.formationData.last_exercice._id == this.exercicesDisplay[i].exercices_id._id) {
					//nouvel exercice non debloqué (next exercice id is already in exercicesDoneData)
					return false;
				}
			}
			return true;
		},
		goToAssistantPage() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-profile-goToAssistantPage');
		},
		goToReferralPage() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-profile-goToReferralPage');
		},
		//savoir si un nouvel exercice a été debloqué
		whoAlreadyDidExercices() {
			//fake it until you make it (but fake it well)
			let max = 90;
			let array = [0, 0, 0, 0, 0, 0, 0, 1, 1, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			let sumArray = array.reduce(function (a, b) {
				return a + b;
			}, 0);
			let sumUntilfCursor = 0;

			let mmt = moment();
			let minuteInTheDay = mmt.clone().startOf('day');
			let diffMinutes = mmt.diff(minuteInTheDay, 'minutes');
			let hourOfTheDay = mmt.get('hour');

			let cursor = hourOfTheDay;

			for (var j = 0; j < array.length && j <= cursor; j++) {
				sumUntilfCursor += array[j];
			}

			var equa = Math.round(((diffMinutes * max) / (24 * 60)) * (sumUntilfCursor / sumArray));

			return equa;
		},
		checkIfTodayExerciceIsDone() {
			var lastExerciceDate = new Date(this.formationData.last_exercice_date);
			var lastExerciceMonth = lastExerciceDate.getMonth();
			var lastExerciceDay = lastExerciceDate.getDate();

			var todayDate = new Date();
			var todayDateMonth = todayDate.getMonth();
			var todayDateDay = todayDate.getDate();
			if (lastExerciceDay === todayDateDay && lastExerciceMonth === todayDateMonth) {
				//exercice of the day is done
				return true;
			} else {
				//exercice of the day is not done yet
				return false;
			}
		},
		todayExercice() {
			this.loading_show = true;
			const urlTarget = this.formationData._id + '/' + this.formationData.last_exercice._id;

			setTimeout(() => {
				this.loading_show = false;
				return this.$router.push('/exercices/' + urlTarget);
			}, this.loading_timeOut);
		},
		convertDate(data) {
			let date = new Date(data).toLocaleDateString();
			return date;
		},
		categorieIsDone(categoryId) {
			return this.categoriesDone.find((singleCat) => {
				return singleCat.category_id._id == categoryId;
			});
		},
		exercicesDisplayed(value) {
			this.exercicesDisplay = value;
		},
		onFinishTourProfile() {
			localStorage.setItem('tour-profile', 'profile');
		},
		checkIfEndDateApprochingTenDaysEnd() {
			var todayDate = new Date();
			var endDate = new Date(this.formationData.end_date);
			var endDateMinusOneMonth = endDate.setDate(endDate.getDate() - 10);

			if (todayDate >= endDateMinusOneMonth) return true;
			else return false;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.v-alert__icon.v-icon {
		margin-top: 16px;
	}
}
#programmes {
	border-radius: 4px;
}
.unlockIcon {
	padding-right: 10px;
}
.lightIconGlobal {
	margin-left: -6px;
	height: 15px;
}
.lightIcon {
	height: 12px;
}
.legend-container {
	margin-left: 10px;
}
.kpiIcons {
	width: 13%;
}
.imageifnoexercice {
	width: 100%;
}
.image-finish-formation {
	width: 75%;
}

.speakerEmoji {
	width: 20px;
	margin-right: 5px;
}

@media only screen and (max-width: 1500px) {
	.fullprofilecontainer {
		padding-left: 3%;
		padding-right: 3%;
	}
}
</style>
