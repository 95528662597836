<template>
	<div class="streak-on-dashboard">
		<v-chip
			v-if="profileInfos && profileInfos.entreprise_id && profileInfos.entreprise_id.slug == 'demo'"
			class="mr-1 interactive-streak demo-streak"
			color="#686bdf"
			text-color="white"
			@click="showDetails"
		>
			<v-avatar left class="white streak pulse-avatar"> 32 </v-avatar>
			<span class="font-weight-bold">Jours d'affilée</span>
			<v-icon right class="star-icon">mdi-star</v-icon>
		</v-chip>
		<template v-else>
			<v-chip v-if="streak" color="#686bdf" text-color="white" class="mr-1 interactive-streak" @click="showDetails">
				<v-avatar left class="white streak pulse-avatar">
					{{ streakNumber }}
				</v-avatar>
				<span class="font-weight-bold">Jour<span v-if="streakNumber > 1">s</span> d'affilée</span>
				<img v-if="streakNumber >= 1" src="@/assets/icons/emoji/gif-flame.gif" alt="flame" class="flame-icon" />
			</v-chip>
		</template>

		<!-- Modal de détails -->
		<StreakDetailsModal v-if="isModalVisible" :streak="streak" :closeModal="hideDetails" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StreakDetailsModal from './StreakDetailsModal.vue';
import { displayStreakNumber } from '@/utils/streak.js';

export default {
	name: 'StreakOnDashboard',
	components: {
		StreakDetailsModal,
	},
	data() {
		return {
			isModalVisible: false,
		};
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		...mapGetters('streak', { streak: 'streak' }),
		streakNumber() {
			return displayStreakNumber(this.streak);
		},
	},
	methods: {
		showDetails() {
			console.log('Opening modal with streak:', this.streakNumber);
			this.isModalVisible = true;
			document.body.style.overflow = 'hidden';
		},
		hideDetails() {
			console.log('Closing modal');
			this.isModalVisible = false;
			document.body.style.overflow = 'auto';
		},
	},
	async created() {
		if (this.streak == null && this.profileInfos._id) {
			await this.$store.dispatch('streak/searchStreakByUserId', { userId: this.profileInfos._id });
		}
	},
};
</script>

<style lang="scss" scoped>
.streak-on-dashboard {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;

	.interactive-streak {
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			transform: scale(1.05);
			box-shadow: 0 0 15px rgba(104, 107, 223, 0.3);
		}

		.flame-icon {
			width: 24px;
			height: 24px;
			object-fit: contain;
			margin-left: 8px;
			margin-top: -2px;
		}

		&.demo-streak {
			.star-icon {
				margin-left: 8px;
			}
		}
	}

	.pulse-avatar {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			animation: pulse 2s infinite;
			background-color: rgba(104, 107, 223, 0.2);
		}
	}

	.streak {
		color: #686bdf;
		font-weight: bold;
		position: relative;
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 0.8;
		}
		50% {
			transform: scale(1.2);
			opacity: 0;
		}
		100% {
			transform: scale(1);
			opacity: 0;
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
</style>
