import axios from 'axios';

const state = {
	weeklyLeaderboard: {
		data: null,
		updatedAt: null,
	},
};

const getters = {
	weeklyLeaderboard: (state) => state.weeklyLeaderboard,
};

const actions = {
	async fetchWeeklyLeaderboard({ commit }, { showAllUsers = false } = {}) {
		const response = await axios.get(`/api/leaderboard/weekly`, {
			params: { showAllUsers: showAllUsers },
		});
		const sortedPositionASC = response.data.data.weeklyLeaderboard.sort((a, b) => a.position - b.position);
		commit('SET_WEEKLY_LEADERBOARD', sortedPositionASC);
		return sortedPositionASC;
	},
};

const mutations = {
	SET_WEEKLY_LEADERBOARD(state, leaderboard) {
		state.weeklyLeaderboard = {
			data: leaderboard,
			updatedAt: new Date(),
		};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
