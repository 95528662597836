<template>
	<div>
		<p class="fs30 font-weight-bold mb-3 mt-3 text-right" style="color: #34e5c8">
			{{ score }} / {{ totalScore }} <img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" />
		</p>

		<v-progress-linear
			:value="(score / totalScore) * 100"
			color="#34e5c8"
			height="10"
			rounded
			background-color="#F1F1F1"
		/>
	</div>
</template>

<script>
export default {
	name: 'CategorySessionPointProgressBar',
	props: {
		score: Number,
		totalScore: Number,
	},
};
</script>

<style lang="scss" scoped>
.iconsBigRules {
	width: 23px;
}
</style>
