<template>
	<div>
		<h2>
			Progression
			<v-tooltip color="black" right>
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
					</v-btn>
				</template>
				<span v-if="totalParcoursSessions">{{ totalSessionsDone }}/{{ totalParcoursSessions }} exercices faits</span>
			</v-tooltip>
		</h2>
		<div class="mx-auto mt-3 min-w-100">
			<div v-if="parcours == null" class="min-w-100 pr-3">
				<v-progress-linear indeterminate color="#686bdf" class="border-radius" rounded height="15"></v-progress-linear>
			</div>
			<div v-else class="parcours-progress-bar d-flex pr-3">
				<div
					class="category"
					v-for="(item, index) in progressData"
					:key="index"
					:class="{ 'last-item': index === progressData.length - 1 }"
				>
					<!-- First item -->
					<v-progress-linear
						v-if="index === 0"
						class="border-radius no-right-border-radius"
						:value="item.completePercent"
						color="#686bdf"
						height="15"
						rounded
						background-color="#F1F1F1"
					/>
					<!-- Last item -->
					<template v-else-if="index === progressData.length - 1">
						<v-progress-linear :value="item.completePercent" color="#686bdf" height="15" background-color="#F1F1F1" />
						<div class="img-container" :class="{ 'completed-bg': item.completePercent === 100 }">
							<img class="trophy" src="@/assets/images/goToReport/end-parcours.png" />
						</div>
					</template>
					<!-- Middle item -->
					<v-progress-linear
						v-else
						:value="item.completePercent"
						color="#686bdf"
						height="15"
						background-color="#F1F1F1"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SessionStatus } from '@/constants/session.js';
export default {
	name: 'RedactionParcoursProgressionBlock',
	props: { parcours: Object },
	data() {
		return {
			progressData: [],
			totalParcoursSessions: null,
			totalSessionsDone: null,
		};
	},
	mounted() {
		this.genData();
	},
	methods: {
		genData() {
			if (this.parcours == null) return;
			const tempArr = [];

			this.parcours.categories.forEach((cat) => {
				const countSessionDone = this.countSessionDone(cat.sessions);
				const totalSessionsOfThisCat = cat.sessions.length;
				const completePercent = (countSessionDone / totalSessionsOfThisCat) * 100;
				tempArr.push({ categoryId: cat._id, completePercent });

				if (this.totalParcoursSessions == null) this.totalParcoursSessions = totalSessionsOfThisCat;
				else this.totalParcoursSessions += totalSessionsOfThisCat;

				if (this.totalSessionsDone == null) this.totalSessionsDone = countSessionDone;
				else this.totalSessionsDone += countSessionDone;
			});

			tempArr.sort((a, b) => b.completePercent - a.completePercent);

			this.progressData = tempArr;
		},
		countSessionDone(sessionssArrayWithStatus) {
			return sessionssArrayWithStatus.filter((session) => session.status === SessionStatus.PASSED).length;
		},
	},
	watch: {
		parcours() {
			this.totalParcoursSessions = null; // Reset state
			this.totalSessionsDone = null; // Reset state
			this.genData();
		},
	},
};
</script>

<style lang="scss" scoped>
.border-radius {
	border-radius: 1rem;
}

.parcours-progress-bar {
	.category {
		min-width: calc(100% / 3); // Hard code 3 chapters
		margin-right: 10px;
	}

	.no-right-border-radius {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.last-item {
		position: relative;
		.img-container {
			position: absolute;
			top: -8px;
			right: -2px;
			background-color: #f1f1f1;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.img-container.completed-bg {
			background-color: #686bdf;
		}
		.trophy {
			width: 30px;
		}
	}
}
</style>
