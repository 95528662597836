const onReceiveMessage = (payload) => {
	console.log('onFirebaseMessageReceive');
	console.log('payload::', payload);
};

const onSuccessGetToken = (currentToken) => {
	console.log('getBrowserToken');
	if (currentToken) {
		// Send the token to your server and update the UI if necessary
		console.log('Token is::', currentToken);
	} else {
		// Show permission request UI
		console.log('No registration token available. Request permission to generate one.');
	}
};

const onErrorGetToken = (error) => {
	console.log('onErrorGetToken::', error);
};

export default {
	onSuccessGetToken,
	onReceiveMessage,
	onErrorGetToken,
};
