<template>
	<div></div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'ToCurrentOrthographiqExercise',

	async mounted() {
		if (localStorage.getItem('token') == null) {
			this.$router.push({ name: 'Login' });
			return;
		}

		const formation = await this.getOrthographiqFormationFormUserToken();
		if (formation == null || formation.last_exercice == null) {
			this.$router.push({ name: 'Dashboard' });
			return;
		}

		this.$router.push({
			name: 'Exercice',
			params: { idFormation: formation._id, idExercice: formation.last_exercice },
		});
	},
	methods: {
		async getOrthographiqFormationFormUserToken() {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
			const formationRes = await axios.get('/api/formation');
			if (formationRes.data.formations.length === 0) return null;

			return formationRes.data.formations.find((f) => f.for_session != true);
		},
	},
};
</script>
