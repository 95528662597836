<template>
	<div>
		<v-card v-if="streak" class="shadow-blue pa30">
			<h2>Série</h2>
			<p class="ortho-text-gray">Ta productivité orthographique s'améliore de jour en jour, sois régulier</p>
			<p class="wording-streak ortho-text-green text-center font-weight-black">
				<span class="mr-1">
					<img src="@/assets/icons/emoji/gif-flame.gif" alt="flame" class="flame-icon" />{{ streakNumber }}
				</span>
				<span>jour<span v-if="streakNumber > 1" style="font-size: 20px">s</span> d'affilée</span>
			</p>
		</v-card>
		<StreakNotificationModal :streak-days="streakNumber" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { displayStreakNumber } from '@/utils/streak.js';
import StreakNotificationModal from './StreakNotificationModal.vue';

export default {
	name: 'StreakInExerciseResult',
	components: {
		StreakNotificationModal,
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		...mapGetters('streak', { streak: 'streak' }),
		streakNumber() {
			return displayStreakNumber(this.streak);
		},
	},
};
</script>

<style lang="scss" scoped>
.flame-icon {
	width: 30px;
	height: 30px;
	margin-bottom: -4px;
}
.wording-streak {
	span:first-child {
		font-size: 30px;
	}
	span:nth-child(2) {
		font-size: 20px;
	}
}
</style>
