<template>
	<div>
		<div v-if="shouldShowModal" class="streak-notification-modal">
			<div class="modal-content">
				<button class="close-button" @click="handleClose">
					<v-icon>mdi-close</v-icon>
				</button>

				<div class="modal-body">
					<div class="icon-container">
						<img src="@/assets/icons/emoji/notify-emoji-bell.gif" class="notify-icon" />
					</div>
					<h3>Ne perds pas ton streak ! 🔥</h3>
					<p>Active les notifications pour maintenir ta série de {{ streakDays }} jours.</p>

					<v-btn color="#15c39a" dark :loading="loading" @click="handleEnableNotifications" class="enable-button">
						Notifie moi si j'oublie
					</v-btn>
				</div>
			</div>
		</div>

		<div v-if="dialog" class="text-center">
			<v-dialog v-model="dialog" max-width="500">
				<v-card class="px-4 py-4">
					{{ unblockInstruction }}
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMessaging, getToken } from 'firebase/messaging';
import { PUBLIC_VAPID_KEY } from '@/config/firebase-messaging.js';

export default {
	name: 'StreakNotificationModal',

	props: {
		streakDays: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			loading: false,
			shouldShowModal: false,
			dialog: false,
			unblockInstruction: '',
		};
	},

	computed: {
		...mapGetters('profile', ['profileInfos']),

		isSafari() {
			const userAgent = navigator.userAgent;
			return userAgent.includes('Safari') && !userAgent.includes('Chrome');
		},
	},

	methods: {
		async checkShouldDisplayModal() {
			// Ne pas afficher sur Safari
			if (this.isSafari) return;

			// Vérifier si l'utilisateur est connecté
			if (!this.profileInfos || Object.keys(this.profileInfos).length === 0) return;

			// Vérifier si l'utilisateur a un streak
			if (!this.streakDays) return;

			// Vérifier si les notifications sont déjà activées
			if (this.profileInfos.allow_push_notification) return;

			// Vérifier le cookie
			const hideUntil = localStorage.getItem('hideNotificationModalUntil');
			if (hideUntil && new Date(hideUntil) > new Date()) return;

			this.shouldShowModal = true;
		},

		detectBrowser() {
			const userAgent = navigator.userAgent;

			if (userAgent.includes('Chrome') && !userAgent.includes('Edge') && !userAgent.includes('OPR')) {
				return 'Chrome';
			} else if (userAgent.includes('Firefox')) {
				return 'Firefox';
			} else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
				return 'Safari';
			} else if (userAgent.includes('Edg')) {
				return 'Edge';
			} else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
				return 'Opera';
			} else {
				return 'Other';
			}
		},

		showUnblockInstructions() {
			const browser = this.detectBrowser();
			let instruction = '';
			if (browser === 'Chrome') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Chrome, allez dans Paramètres > Confidentialité et sécurité > Paramètres des sites > Notifications et débloquez ce site.`;
			} else if (browser === 'Firefox') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Firefox, allez dans Paramètres > Vie privée et sécurité > Permissions > Notifications, puis autorisez les notifications pour ce site.`;
			} else if (browser === 'Safari') {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : dans Safari, allez dans Préférences > Sites web > Notifications et modifiez les paramètres pour ce site.`;
			} else {
				instruction = `Vous avez bloqué les notifications pour ce site. Pour activer les notifications : veuillez vérifier les paramètres de votre navigateur pour débloquer les notifications.`;
			}
			this.unblockInstruction = instruction;
			this.dialog = true;
		},

		async handleEnableNotifications() {
			this.loading = true;
			try {
				const permission = await Notification.requestPermission();

				if (permission === 'granted') {
					const messaging = getMessaging();
					const token = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });
					await this.$store.dispatch('profile/turnOnPushNotification', { newToken: token });
					this.shouldShowModal = false;
				} else if (permission === 'denied') {
					this.showUnblockInstructions();
				}
			} catch (error) {
				console.error("Erreur lors de l'activation des notifications:", error);
			}
			this.loading = false;
		},

		handleClose() {
			const fiveDaysFromNow = new Date();
			fiveDaysFromNow.setDate(fiveDaysFromNow.getDate() + 2);
			localStorage.setItem('hideNotificationModalUntil', fiveDaysFromNow.toISOString());
			this.shouldShowModal = false;
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.checkShouldDisplayModal();
		});
	},

	watch: {
		profileInfos: {
			handler() {
				this.checkShouldDisplayModal();
			},
			immediate: true,
		},
		streakDays: {
			handler() {
				this.checkShouldDisplayModal();
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.notify-icon {
	width: 50px;
}
.streak-notification-modal {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
	max-width: 400px;
	width: 90%;
}

.modal-content {
	background: white;
	border-radius: 12px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	padding: 20px;
	position: relative;
	animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
	from {
		transform: translateY(100px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	cursor: pointer;
	padding: 5px;
	opacity: 0.6;
	transition: opacity 0.2s;
}

.close-button:hover {
	opacity: 1;
}

.modal-body {
	text-align: center;
	padding: 10px 0;
}

.icon-container {
	margin-bottom: 15px;
}

h3 {
	color: #333;
	margin-bottom: 10px;
	font-size: 1.2em;
}

p {
	color: #666;
	margin-bottom: 20px;
	font-size: 0.95em;
	line-height: 1.4;
}

.enable-button {
	width: 100%;
	margin-top: 10px;
	text-transform: none;
}
</style>
