<template>
	<div class="test-question">
		<div class="ortho-bold test-position-question">
			<span>{{ testTrack.question }}</span>
		</div>
		<div v-if="testTrack && testTrack.instruction" class="test-position-action-container">
			<p>{{ testTrack.instruction }}</p>
		</div>
		<div class="test-position-answers-container">
			<v-form @submit.prevent="onClickContinueBtn">
				<v-text-field v-model="answer1" label="" outlined color="black" class="input-field" />
				<v-text-field
					v-if="testTrack.answers.length === 2"
					v-model="answer2"
					label=""
					outlined
					color="black"
					class="input-field"
				/>
				<v-btn type="submit" :disabled="valid === false" depressed @click="onClickContinueBtn()" color="black">
					<span class="white--text">Continuer</span>
				</v-btn>
			</v-form>
		</div>
	</div>
</template>

<script>
export default {
	name: 'QuestionFillBlanks',
	props: { testTrack: Object, onClickAnswer: Function },

	data: () => ({
		rules: [(v) => !!v],
		valid: false,
		answer1: '',
		answer2: '',
	}),

	methods: {
		onClickContinueBtn() {
			if (this.testTrack.answers.length === 2) {
				this.onClickAnswer(`${this.answer1.trim().toLowerCase()}&${this.answer2.trim().toLowerCase()}`);
			} else this.onClickAnswer(this.answer1.trim().toLowerCase());

			this.answer1 = '';
			this.answer2 = '';
		},
		checkAnswersTextField() {
			if (this.testTrack.answers.length === 2) {
				if (this.answer1 !== '' && this.answer2 !== '') this.valid = true;
				else this.valid = false;
			} else {
				if (this.answer1 !== '') this.valid = true;
				else this.valid = false;
			}
		},
	},
	watch: {
		answer1() {
			this.checkAnswersTextField();
		},
		answer2() {
			this.checkAnswersTextField();
		},
	},
};
</script>

<style lang="scss" scoped>
// Style in styles.css
.input-field {
	max-width: 150px;
	margin: 0 auto;
}
</style>
