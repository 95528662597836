<template>
	<div>
		<Loading :show="loading" label="3, 2, 1..."></Loading>
		<v-col offset-md="3" xl="6" md="6" sm="12" cols="12" v-if="totalScoreInPercent">
			<CreateTestComponent
				v-if="totalScoreInPercent >= 80"
				:formationProgress="formationProgress"
				:categoryId="allExerciceFromFormationCategory._id"
			/>
			<GoToReportBlock
				v-if="totalScoreInPercent >= 80"
				:totalScoreInPercent="totalScoreInPercent"
				:formationProgress="formationProgress"
				:categoryId="allExerciceFromFormationCategory._id"
			/>
			<ScoreFinal v-else :totalScoreInPercent="totalScoreInPercent" />
			<FeedbacksExercice :totalScoreInPercent="totalScoreInPercent" />
			<ButtonsFinal v-if="!loading" class="mt-8" :totalScoreInPercent="totalScoreInPercent" />
			<StreakInExerciseResult class="mt-8" />
			<ChartsResultExercice :totalScoreInPercent="totalScoreInPercent" class="mt-8" />
			<ScoreByStories :stories="stories" :resultToDisplay="resultToDisplay" class="mt-8" />
			<a v-if="isUserOnAndroidApp" id="ReviewAndroidAppLink" class="invisible" href="orthographiq-app://review"
				>Rate app</a
			>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-full-loading';
import ScoreFinal from './default/ScoreFinal';
import FeedbacksExercice from './default/FeedbacksExercice';
import ButtonsFinal from './default/ButtonsFinal';
import ChartsResultExercice from './default/ChartsResultExercice';
import ScoreByStories from './default/ScoreByStories';
import GoToReportBlock from './default/reports/GoToReportBlock';
import StreakInExerciseResult from '@/components/streak/StreakInExerciseResult.vue';
import { delay } from '@/utils/helper.js';
import { detectAndroidUserByReferrer } from '../../../utils/helper';
import CreateTestComponent from '@/components/create-test/CreateTestComponent.vue';
import { logBugOnServer } from '@/utils/debug.js';

export default {
	name: 'Default',
	components: {
		ScoreFinal,
		ButtonsFinal,
		FeedbacksExercice,
		StreakInExerciseResult,
		ChartsResultExercice,
		ScoreByStories,
		GoToReportBlock,
		Loading,
		CreateTestComponent,
	},
	data() {
		return {
			loading: false,
			resultToDisplay: [],
			isUserOnAndroidApp: detectAndroidUserByReferrer(),
		};
	},
	computed: {
		...mapGetters('exercice', {
			resultExercice: 'resultExercice',
			scoreExercice: 'scoreExercice',
			resultStory: 'resultStory',
			allExerciceFromFormationCategory: 'allExerciceFromFormationCategory',
			exercice: 'exerciceInfos',
		}),
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			exercicesInfos: 'exercicesInfos',
			formationInfos: 'formationInfos',
			formationProgress: 'formationProgress',
		}),
		...mapGetters('formation', {
			formationData: 'formationData',
		}),
		totalScoreInPercent() {
			let total = 0;
			let sumQuestion = 0;
			let scoreRoute = null;
			if (this.$router.currentRoute.name == 'Story') {
				scoreRoute = this.resultStory;
			} else {
				scoreRoute = this.resultExercice;
			}
			scoreRoute.map((element) => {
				element.questions_id.map((question) => {
					if (question.is_good_answer == true) {
						total++;
					}
					sumQuestion++;
				});
			});
			return Math.ceil((total / sumQuestion) * 100);
		},
	},
	props: {
		stories: Array,
		idFormation: { type: String, required: true },
		idExercice: { type: String, required: true },
	},
	async mounted() {
		this.loading = true;
		if (this.shouldDisplayReviewAndroidApp() === true) {
			this.openAndroidInAppReview();
		}
		window.scrollTo(0, 0);

		try {
			if (this.$router.currentRoute.name == 'Exercice') {
				await Promise.all([
					this.postScoreExercice({
						score: this.resultExercice,
						idFormation: this.idFormation,
						idExercice: this.idExercice,
						scoreGlobal: this.scoreExercice,
					}),
					this.submitNewStreakActivity(),
				]);

				await delay(2000);
				this.resultToDisplay = this.resultExercice;
			} else {
				await Promise.all([
					this.postScoreStory({
						idExercice: this.idExercice,
						idFormation: this.idFormation,
						scoreStory: this.resultExercice,
					}),
					this.submitNewStreakActivity(),
				]);
				this.resultToDisplay = this.resultStory;
			}
		} catch (error) {
			console.error(error);
			await logBugOnServer(
				'TO426',
				`component::@/components/exercice/results/Default.vue ::: props values::idFormation=${this.idFormation} & idExercice=${this.idExercice} :: router params::idFormation=${this.$route.params.idFormation} & idExercice=${this.$route.params.idExercice}:::errorMessage=${error.message}`
			);
		}

		if (this.totalScoreInPercent >= 80) {
			this.$confetti.start({ particlesPerFrame: 0.04 });
		}

		await this.getFormationData({ idFormation: this.$route.params.idFormation });
		this.loading = false;
	},
	methods: {
		...mapActions('exercice', ['postScoreExercice']),
		...mapActions('exercice', ['postScoreStory']),
		...mapActions('formation', ['getFormationData']),
		shouldDisplayReviewAndroidApp() {
			const uniqueExerciseEligibleToreview = [2, 3, 4, 5, 10, 50, 75, 78, 79];

			if (this.profileInfos == null || this.exercicesInfos == null) return false;
			if (this.isUserOnAndroidApp === false) return false;
			const profileActive = this.profileInfos.is_natif;
			const numUniqueExerciseDone = this.exercicesInfos.length;
			if (
				profileActive === true &&
				uniqueExerciseEligibleToreview.includes(numUniqueExerciseDone) === true &&
				this.totalScoreInPercent >= 80
			)
				return true;
			return false;
		},
		openAndroidInAppReview() {
			const reviewLinkElement = document.querySelector('#ReviewAndroidAppLink');
			if (reviewLinkElement != null) reviewLinkElement.click();
		},
		async submitNewStreakActivity() {
			if (this.profileInfos._id)
				await this.$store.dispatch('streak/submitDailyStreakByUserId', { userId: this.profileInfos._id });
			else if (this.$route.params.idFormation)
				await this.$store.dispatch('streak/submitDailyStreakByFormationId', {
					formationId: this.$route.params.idFormation,
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.border-result {
	padding: 2rem;
}
</style>
