var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"shadow-blue-not-openned",class:{
		'pa10 mb20': _vm.$vuetify.breakpoint.smAndDown,
		'pa15 mb20': _vm.$vuetify.breakpoint.mdAndUp,
	}},[_c('h2',{staticClass:"mb20"},[_vm._v("Dernières règles")]),_vm._l((_vm.rulesUser.slice(0, 3)),function(rule,index){return _c('div',{key:index},[_c('v-row',{staticClass:"rulePresentation"},[_c('div',[_c('p',{staticClass:"greyLight mb-0 rule-name",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToRuleDetails(rule)}}},[_vm._v(" "+_vm._s(_vm._f("tronc")(rule.name))+" ")])]),_c('div',[_c('p',{staticClass:"font-weight-bold ortho-text-green-flash mb-0 d-flex justify-end align-center",class:{
						greenText: rule.score < 150,
						goldText: rule.score >= 150,
					}},[(rule.score < 150)?_c('v-icon',{staticClass:"iconsRules mr-1",attrs:{"color":"#34e5c8"}},[_vm._v("mdi-checkbox-marked-circle")]):_vm._e(),(rule.score >= 150)?_c('v-icon',{staticClass:"iconsRules mr-1",attrs:{"color":"#FFD15E"}},[_vm._v("mdi-star")]):_vm._e(),_vm._v(" "+_vm._s(rule.score)+" "),(rule.score < 150)?_c('img',{staticClass:"imgRules",attrs:{"src":require("@/assets/icons/lightening-green.svg")}}):_vm._e(),(rule.score >= 150)?_c('img',{staticClass:"imgRules",attrs:{"src":require("@/assets/icons/lightening-gold.svg")}}):_vm._e(),_vm._v("/ "+_vm._s(rule.pointTarget)+" ")],1)]),_c('v-progress-linear',{staticClass:"progess-bar-score-rules",attrs:{"value":(rule.score / rule.pointTarget) * 100,"height":"6","color":_vm.colorRules(rule.score),"rounded":"","background-color":"#F1F1F1"}})],1)],1)}),_c('div',{staticClass:"cta-div"},[_c('a',{staticClass:"fs12 ortho-bold ortho-text-gray to-rule-page-href d-flex cta",staticStyle:{"display":"block","margin":"0 auto","width":"fit-content","text-align":"center"},attrs:{"href":_vm.$router.resolve({
					name: 'Rules',
					params: {
						idFormation: _vm.formationData._id,
					},
				}).href},on:{"click":function($event){return _vm.goToRulesPage()}}},[_vm._v("ESPACE RÈGLES "),_c('v-icon',{staticClass:"mr3 arrow-right",attrs:{"color":"#979FA9","small":""}},[_vm._v("mdi-arrow-right")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }