<template>
	<div>
		<Error v-if="error"></Error>
		<div v-else>
			<component :is="initComponent" :idExercice="idExercice" :idFormation="idFormation" />
		</div>
		<!-- <ModalFormationEndIn10Days
			:enterprise="profileInfos.entreprise_id"
			:formationData="formationInfos"
		></ModalFormationEndIn10Days> -->
	</div>
</template>

<script>
import Error from '../../views/layouts/Error';
import ModalFormationEndIn10Days from '../../components/other/ModalFormationEndIn10Days';
import { mapGetters } from 'vuex';
import { substractFromNow } from '../../utils/helper';

export default {
	name: 'ExerciceComponent',
	components: {
		Error,
		ModalFormationEndIn10Days,
	},
	props: {
		idExercice: { type: String, required: true },
		idFormation: { type: String, required: true },
	},
	computed: {
		...mapGetters('exercice', {
			exerciceType: 'exerciceType',
		}),
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationInfos: 'formationInfos',
			formationProgress: 'formationProgress',
		}),
	},
	data() {
		return {
			error: false,
			initComponent: '',
		};
	},
	methods: {
		substractFromNow,
	},
	async created() {
		try {
			if (this.profileInfos.entreprise_id == null && localStorage.getItem('token'))
				await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos');

			await this.$store.dispatch('exercice/getExercice', {
				idFormation: this.idFormation,
				idExercice: this.idExercice,
			});
			await this.$store.dispatch('exercice/resetScoreExercice');
			// if (this.profileInfos.entreprise_id  && this.profileInfos.entreprise_id._id == null && localStorage.getItem('token') != null) {
			// 	await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos');
			// }

			if (
				this.idExercice != null &&
				this.idExercice != 'undefined' &&
				this.idExercice != 'null' &&
				this.idExercice != ''
			) {
				await this.$store.dispatch('exercice/getNoFeedbacksExercices', {
					exerciceId: this.idExercice,
					after: this.substractFromNow(5),
					rating: 5,
					userId: this.profileInfos._id,
				});
			}

			if (this.formationProgress == null) {
				this.$store.dispatch('profile/formationProgress', { idFormation: this.idFormation });
			}

			this.initComponent = async () => await import(`./type/${this.exerciceType}.vue`);
		} catch (err) {
			console.error(err);
			this.error = true;
		}
	},
};
</script>
