<template>
	<div>
		<div class="progression-title-container mb-3">
			<h2 class="fs25">Progression</h2>
			<span v-if="loading" class="fs25 font-weight-bold" style="color: #34e5c8">
				0
				<img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" />
				/ 1500</span
			>
			<span v-else-if="globalScoreRules >= 1500" class="fs25 font-weight-bold" style="color: #34e5c8">
				{{ globalScoreRules }}
				<img class="iconsBigRules" src="@/assets/icons/lightening-gold.svg" />/
				{{ globalTargetScoreRules }}
			</span>
			<span v-else class="fs25 font-weight-bold" style="color: #34e5c8">
				{{ globalScoreRules }}
				<img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" />/
				{{ globalTargetScoreRules }}
			</span>
		</div>

		<v-progress-linear
			:value="loading ? 0 : (globalScoreRules / globalTargetScoreRules) * 100"
			:color="colorScore(globalScoreRules)"
			height="9"
			rounded
			background-color="#F1F1F1"
		></v-progress-linear>
	</div>
</template>

<script>
export default {
	name: 'CategoryPointProgressBar',
	props: {
		globalScoreRules: Number,
		globalTargetScoreRules: Number,
		loading: Boolean,
	},

	methods: {
		colorScore(value) {
			if (value == 1500) return '#FFD15E';
			else return '#34e5c8';
		},
	},
};
</script>

<style lang="scss" scoped>
.progression-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.iconsBigRules {
	width: 18px;
}
</style>
