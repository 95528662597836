<template>
	<div class="d-flex align-center justify-center flex-column" style="min-height: 75vh">
		<h1 class="mb-4">Vous n'êtes inscrit à aucune formation</h1>
		<p>Nous vous invitons à nous contacter par le chat ou à vous connectez à nouveau</p>
		<v-btn depressed large dark color="black" class="mt-3" @click="logout()">Me reconnecter</v-btn>
	</div>
</template>

<script>
export default {
	name: 'NoFormation',
	methods: {
		logout() {
			this.$router.push({ name: 'Logout' });
		},
	},
};
</script>
