<template>
	<v-app>
		<LoadingPageWithQuote v-if="isSwitchFormationStateLoading" />
		<TrialFinished
			v-if="
				formationInfos.test_trial &&
				this.$router.currentRoute.name != 'Onboarding' &&
				this.$router.currentRoute.name != 'TestPositionnement' &&
				Object.keys(formationInfos).length != 0 &&
				this.$router.currentRoute.name != 'ResultsDetail'
			"
		/>
		<!-- hidded 13/05/2024
		<Announcement
			v-if="
				!formationInfos.test_trial &&
				this.$router.currentRoute.name != 'Onboarding' &&
				this.$router.currentRoute.name != 'TestPositionnement' &&
				Object.keys(formationInfos).length != 0 &&
				this.$router.currentRoute.name != 'ResultsDetail'
			"
		></Announcement> -->
		<CookieConsent />
		<NavBar
			:categoriesDone="categoriesDone"
			:formationInfos="formationInfos"
			:currentRoute="this.$router.currentRoute"
		/>
		<v-content>
			<router-view v-if="accessToApp"></router-view>
			<NotOptimized v-else></NotOptimized>
		</v-content>
		<WebPushNotificationPermission />
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { firebaseConfig } from '@/config/firebase-messaging.js';
import firebaseMessaging from '@/utils/firebase-messaging.js';

import '@/assets/styles.css';
import '@/assets/css/custom-introjs.scss';
import NotOptimized from '@/views/layouts/NotOptimized';
import TrialFinished from '@/components/other/TrialFinished';
// import Announcement from './components/layouts/Announcement';
import NavBar from './components/navbar/index.vue';
import { setAppContextOnLocalStorage } from './utils/context';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';
import LoadingPageWithQuote from './components/loading-page-with-quote/LoadingPageWithQuote.vue';
import WebPushNotificationPermission from './components/other/WebPushNotificationPermission.vue';

/** Firebase registered */
// const userToken = localStorage.getItem('token');

initializeApp(firebaseConfig);
const messaging = getMessaging();
onMessage(messaging, firebaseMessaging.onReceiveMessage);

// initializeApp(firebaseConfig);
// const messaging = getMessaging();
// onMessage(messaging, firebaseMessaging.onReceiveMessage);
// getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY })
// 	.then(firebaseMessaging.onSuccessGetToken)
// 	.catch(firebaseMessaging.onErrorGetToken);

export default {
	name: 'App',
	components: {
		NotOptimized,
		LoadingPageWithQuote,
		TrialFinished,
		NavBar,
		// Announcement,
		CookieConsent,
		WebPushNotificationPermission,
	},
	computed: {
		...mapGetters('profile', {
			formationInfos: 'formationInfos',
			categoriesDone: 'categoriesDone',
		}),
		...mapGetters('appState', {
			selectingFormationId: 'selectingFormationId',
			isSwitchFormationStateLoading: 'isSwitchFormationStateLoading',
		}),
	},
	data: () => {
		return {
			accessToApp: true,
			openMenu: false,
		};
	},
	methods: {
		setAppContextOnLocalStorage,
	},
	async created() {
		try {
			this.setAppContextOnLocalStorage();
		} catch (error) {
			console.log(error);
		}
	},
	watch: {
		selectingFormationId() {
			this.$store.dispatch('profile/getProfileFormationInfos', { formationId: this.selectingFormationId });
		},
	},
};
</script>
