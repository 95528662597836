<template>
	<div>
		<ChapterSessionProgressionBlock class="mb-9" v-if="selectingCategory" :category="selectingCategory" />
		<div class="pb-3 mb-9">
			<ChapterDetailHeader :selectingCategory="selectingCategory" />
		</div>
		<ListSessions v-if="selectingCategory" :sessions="selectingCategory.sessions" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChapterDetailHeader from '@/components/dashboard/profile/chapter/ChapterDetailHeader.vue';
import ListSessions from '@/components/dashboard/profile/sessions/ListSessions.vue';
import ChapterSessionProgressionBlock from '@/components/dashboard/profile/sessions/ChapterSessionProgressionBlock.vue';

export default {
	name: 'RedactionChapterDetail',
	components: {
		ChapterDetailHeader,
		ListSessions,
		ChapterSessionProgressionBlock,
	},
	data() {
		return {
			selectingCategory: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	mounted() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.getCategoryInParcours(currentParcoursItem.parcours_id, this.$route.params.idCategory);
		},
		getCategoryInParcours(parcours, idCategory) {
			const selectingCategory = parcours.categories.find((category) => category._id === idCategory);
			this.selectingCategory = selectingCategory;
		},
	},
};
</script>

<style lang="scss" scoped></style>
