<template>
	<v-container fluid class="pt-0 pb-O">
		<ExerciceComponent v-if="exerciseID && formationID" :idExercice="exerciseID" :idFormation="formationID" />
	</v-container>
</template>

<script>
import ExerciceComponent from '@/components/exercice/Index.vue';

export default {
	name: 'Exercice',
	components: {
		ExerciceComponent,
	},
	data() {
		return {
			exerciseID: null,
			formationID: null,
		};
	},
	created() {
		// Guard make sure have exerciseID && formationID before render exercise component
		const exerciseID = this.$route.params.idExercice || null;
		const formationID = this.$route.params.idFormation || null;
		if (exerciseID == null || exerciseID == 'null' || exerciseID == 'undefined') {
			this.$router.push({ name: 'Dashboard' });
			return;
		}
		if (formationID == null || formationID == 'null' || formationID == 'undefined') {
			this.$router.push({ name: 'Dashboard' });
			return;
		}

		this.exerciseID = exerciseID;
		this.formationID = formationID;
	},
};
</script>
