<template>
	<div class="rules-mobile-wrapper">
		<div class="rules-mobile-overlay" :class="animationClassName" @click="closeModalInParent"></div>
		<div class="rules-mobile-modal" :class="animationClassName">
			<v-container>
				<div class="header text-right">
					<v-btn text icon class="close-btn" @click="closeModalInParent">
						<v-icon x-large color="black">mdi-close</v-icon>
					</v-btn>
				</div>

				<div class="rules-mobile-modal-content">
					<RulesInfos
						:infosRules="infosRules"
						:idFormation="idFormation"
						@displayRuleFromSession="$emit('displayRuleFromSession', $event)"
					/>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
import RulesInfos from './RulesInfos';

export default {
	name: 'RulesMobileDialog',
	components: { RulesInfos },
	props: {
		infosRules: {
			type: Object,
			required: true,
		},
		idFormation: {
			type: String,
			required: true,
		},
		closeModal: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			animationClassName: '',
			timeOutObject: null,
		};
	},
	created() {
		this.timeOutObject = setTimeout(() => (this.animationClassName = 'slide_up'), 100);
	},
	beforeDestroy() {
		if (this.timeOutObject) clearTimeout(this.timeOutObject);
	},
	methods: {
		closeModalInParent() {
			this.animationClassName = 'slide_down';

			if (this.timeOutObject) clearTimeout(this.timeOutObject);
			this.timeOutObject = setTimeout(() => this.closeModal(), 800);
		},
	},
};
</script>

<style lang="scss" scoped>
.rules-mobile-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}

.rules-mobile-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: opacity 0.3s ease;
}

.rules-mobile-modal {
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: white;
	border-top-left-radius: 1.2rem;
	border-top-right-radius: 1.2rem;
	transition: all 0.8s;
	transform: translateY(100%);
	width: 100vw;
	z-index: 1000;

	.header {
		height: 50px;
		padding: 8px;
	}

	.rules-mobile-modal-content {
		overflow-y: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding: 0 16px 50px;
	}

	.rules-mobile-modal-content::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 959px) {
		height: 80vh;
		.rules-mobile-modal-content {
			height: calc(80vh - 50px);
		}
	}

	@media screen and (max-width: 375px) {
		height: 85vh;
		.rules-mobile-modal-content {
			height: calc(85vh - 50px);
		}
	}
}

.slide_up {
	transform: translateY(0);
	&.rules-mobile-overlay {
		opacity: 1;
	}
}

.slide_down {
	transform: translateY(100%);
	&.rules-mobile-overlay {
		opacity: 0;
	}
}

@media screen and (max-width: 600px) {
	.rules-mobile-modal-content {
		padding: 0 0px 50px !important;
	}
}
</style>
