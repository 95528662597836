import { render, staticRenderFns } from "./ParcoursDetail.vue?vue&type=template&id=4248f213&scoped=true&"
import script from "./ParcoursDetail.vue?vue&type=script&lang=js&"
export * from "./ParcoursDetail.vue?vue&type=script&lang=js&"
import style0 from "./ParcoursDetail.vue?vue&type=style&index=0&id=4248f213&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4248f213",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol})
