<template>
	<div v-if="formationProgress">
		<v-row
			class="fullAssistantContainer"
			:class="{
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<div class="ortho-text-blue mb5 d-flex">
				<h3
					class="assistant-link ml-4"
					@click="showAssistant = true"
					:class="{
						menuSelected: showAssistant == true,
					}"
				>
					🔎 Vérifie et améliore ton texte
				</h3>
				<h3
					class="ortho-text-gray assistant-link ml-6 d-none"
					@click="showAssistant = false"
					:class="{
						menuSelected: showAssistant == false,
					}"
				>
					Mes enregistrements
				</h3>
			</div>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-if="showAssistant == true"
		>
			<v-col md="8" cols="12">
				<div class="container-textarea">
					<v-card class="shadow-blue-not-openned mb-5">
						<v-container fluid>
							<div
								contenteditable="true"
								class="divEditionText hide-scroll-bar fs14"
								rows="15"
								v-html="text"
								ref="text"
								style="overflow: scroll"
							>
								{{ text }}
							</div>
						</v-container>
						<p v-if="showConfirmation == true" class="mb-0 mr-4 ortho-text-green text-right">Texte enregistré !</p>
						<v-container
							fluid
							class="d-flex"
							:class="{
								'justify-end': checking.length != 0,
								'justify-end align-left': checking.length == 0,
							}"
						>
							<v-btn v-show="false" large depressed class="shades white black--text mr-1" @click="registerText()"
								>Enregistrer mon texte</v-btn
							>
							<!-- <star-rating
								class="mr-3 ratingStars"
								v-model="rating"
								:star-size="30"
								:show-rating="false"
								v-if="checking.length != 0 && showRating == true"
								@rating-selected="getRating()"
							/> -->
							<div v-if="showRating == false">
								<v-alert dense outlined type="success" color="#15c39a" class="mb-0 mr-1"
									>Ton avis a bien été envoyé, merci</v-alert
								>
							</div>
							<div class="actionBtnContainer">
								<v-btn
									v-if="formationProgress.user.entreprise_id.slug == 'demo' || btnExample"
									class="actionBtnAssistant mr-2"
									depressed
									large
									dark
									color="black"
									:loading="loading"
									@click="submitExample()"
									>Montre moi un exemple</v-btn
								>
								<v-btn
									class="actionBtnAssistant mr-2"
									depressed
									large
									dark
									color="#15c39a"
									:loading="loading"
									@click="submit()"
									>Voir mes erreurs</v-btn
								>
								<v-btn
									v-if="reformulatedBtn"
									class="actionBtnAssistant"
									depressed
									large
									dark
									outlined
									color="#7854f7"
									:loading="loadingReformulation"
									@click="reformulateText()"
								>
									Corriger et reformuler
								</v-btn>
								<v-btn
									v-if="!reformulatedBtn"
									class="actionBtnAssistant"
									depressed
									large
									dark
									outlined
									color="#7854f7"
									@click="refresh()"
								>
									<v-icon dark> mdi-refresh </v-icon>
								</v-btn>
							</div>
						</v-container>
					</v-card>
					<v-card
						v-if="reformulatedText"
						class="shadow-blue-not-openned"
						style="border: 1px solid #7854f7; background-color: #e9edff"
					>
						<v-container fluid>
							<h3 class="mb-5" style="color: #7854f7">✨ Proposition de reformulation</h3>
							<div class="fs14">
								<p>{{ reformulatedText }}</p>
							</div>
							<div
								fluid
								class="d-flex mt-5"
								:class="{
									'justify-end': checking.length != 0,
									'justify-end align-left': checking.length == 0,
								}"
							>
								<v-btn
									class="actionBtnAssistant"
									icon
									color="#7854f7"
									:loading="loadingReformulation"
									@click="copyToClipboard(reformulatedText)"
								>
									<v-icon dark> mdi-content-copy </v-icon>
								</v-btn>
							</div>
							<v-snackbar v-model="snackbarCopied" left color="#7854f7" :timeout="3000">
								Texte copié dans le presse-papier !
							</v-snackbar>
						</v-container>
					</v-card>

					<v-card
						color="#7854f7"
						v-if="!reformulatedText && sectionReformulation == true"
						class="shadow-lg d-flex flex-column align-center justify-center"
						style="padding: 24px"
					>
						<v-container fluid class="text-center">
							<h3 class="white--text mb-5 font-weight-bold" style="font-size: 1.5rem">
								🚀 <span style="text-decoration: underline">Reformule</span> ton écriture avec style !
							</h3>
							<p class="white--text fs16" style="margin-bottom: 1.5rem">
								Transforme tes textes en un clin d'œil grâce à notre outil de reformulation intelligent.<br />
							</p>
							<p class="white--text fs16" style="margin-bottom: 1.5rem">
								<strong>Saisis ton texte ci-dessus et clique sur 'REFORMULER'</strong>
							</p>
						</v-container>
					</v-card>

					<v-row class="mt20">
						<v-col md="8" cols="12"> </v-col>
					</v-row>
				</div>
			</v-col>
			<v-col md="4" cols="12" :class="{ mt20: $vuetify.breakpoint.smAndDown }">
				<div v-if="checking.length != 0" class="ortho-text-blue assistant-container mb20">
					<img src="@/assets/icons/assistant.svg" class="assistant-icon" alt="" />
					<h2 class="ortho-text-gray">{{ checking.length }} suggestions</h2>
				</div>
				<div v-if="checking.length == 0" class="searching-image-container">
					<img src="@/assets/images/searching.svg" class="searching-image" alt="" />
				</div>
				<v-card v-else class="shadow-blue-not-openned mb20" v-for="(check, index) in checking" :key="index">
					<div class="suggestion-container" @mouseover="highlightError(index)" @mouseleave="initColorError(index)">
						<div class="align-items mb10">
							<v-icon class="iconError fs10" :class="check.issueType">fas fa-circle</v-icon>
							<span class="ml5 fs12 suggestion-topic"
								><strong v-if="check.issueType == 'typographical'">Typographie</strong>
								<strong v-else-if="check.issueType == 'misspelling'">Faute de frappe</strong>
								<strong v-else-if="check.issueType == 'style'">Style</strong>
								<strong v-else>Autre</strong>
							</span>
						</div>
						<div class="align-items ml15">
							<div v-if="check.replacements.length > 0" class="align-items container-chip row">
								<v-chip
									v-for="(remplacement, index) in limitArray(check.replacements || [])"
									:key="index"
									class="suggestion-chips"
									color="#15c39a"
									label
								>
									{{ remplacement.value }}
								</v-chip>
							</div>
							<p v-else><strong>Mot non reconnu</strong></p>
						</div>

						<div class="align-items ml15">
							<p>
								{{ check.message }}
							</p>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col md="8" cols="12">
				<v-alert v-if="error" type="error" class="mt20" prominent outlined>Une erreur s'est produite</v-alert>
			</v-col>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-else
		>
			<TextsSaved :formationId="formationProgress.formation._id" />
		</v-row>
		<v-row justify="center">
			<v-dialog v-model="noError" persistent max-width="430">
				<v-card>
					<v-card-title class="headline"
						>Bravo {{ formationProgress.user.first_name }}, aucune erreur détectée 🎉</v-card-title
					>
					<v-card-text>Nous n'avons pas détecté d'erreur dans ton texte.</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="#15c39a" text @click="closeNoErrorModal()">OKAY</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<ModalForRestrictedUser :testTrialData="formationProgress.formation.test_trial" modalName="assistantPage" />
		<v-snackbar v-model="snackbar" left color="#2dc7ae" :timeout="3000">
			<div class="d-flex justify-space-between align-center">
				<h3>Personnalisation de ta formation selon tes erreurs</h3>
			</div>
		</v-snackbar>
		<v-snackbar v-model="snackbarErrorTextEmpty" left color="warning" :timeout="3000">
			<div class="d-flex justify-space-between align-center">
				<h3>Il faut d'abord écrire un texte.</h3>
			</div>
		</v-snackbar>
	</div>
</template>

<script>
import Axios from 'axios';
// import StarRating from 'vue-star-rating';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import TextsSaved from './TextsSaved';
import ModalForRestrictedUser from '../other/ModalForRestrictedUser/ModalForRestrictedUser';
import moment from 'moment';

export default {
	name: 'Correcteur',
	data() {
		return {
			text: '',
			noError: false,
			checking: [],
			error: false,
			allTextError: [],
			// rating: 0,
			showRating: null,
			showAssistant: true,
			showConfirmation: false,
			loading: false,
			snackbar: false,
			btnExample: true,
			storageKeySendExample: 'sendAssistantExample',
			sectionReformulation: true,
			storageKeySendExampleReformulation: 'sectionReformulationExample',
			loadingReformulation: false,
			reformulatedText: '',
			reformulatedBtn: true,
			snackbarCopied: false,
			axiosSource: Axios.CancelToken.source(),
			snackbarErrorTextEmpty: false,
		};
	},
	components: {
		// StarRating,
		TextsSaved,
		ModalForRestrictedUser,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	mounted() {
		this.btnExample = !window.localStorage.getItem(this.storageKeySendExample);
		this.sectionReformulation = !window.localStorage.getItem(this.storageKeySendExampleReformulation);
	},
	destroyed() {
		this.axiosSource.cancel();
	},
	methods: {
		...mapActions('assistant', ['getReformulationFromAI']),

		hashText(text) {
			let hash = 0,
				i,
				chr;
			if (text.length === 0) return hash;
			for (i = 0; i < text.length; i++) {
				chr = text.charCodeAt(i);
				hash = (hash << 5) - hash + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash.toString();
		},

		refresh() {
			window.location.reload();
		},
		async reformulateText() {
			try {
				// Vérifie s'il y a du texte dans l'élément
				const textContent = this.$refs.text && this.$refs.text.innerText ? this.$refs.text.innerText.trim() : '';

				if (!textContent) {
					return (this.snackbarErrorTextEmpty = true);
				}

				// Ajoute le cookie pour masquer la section de l'exemple de reformulation
				const newCookieReformulation = { hideUntil: moment().add(100, 'days').unix() };
				window.localStorage.setItem(this.storageKeySendExampleReformulation, JSON.stringify(newCookieReformulation));

				this.loadingReformulation = true;

				// Prépare et envoie la question
				const promptReformulation =
					"Reformule et corrige le texte suivant (retire tous les elements HTML s'il y en a), ne pas ajouter de conseils, juste la phrase :";
				const question = promptReformulation + ' ' + textContent;

				const answer = await this.getReformulationFromAI({
					question,
					config: { cancelToken: this.axiosSource.token },
				});

				this.reformulatedText = answer;
			} catch (error) {
				console.error('Erreur lors de la reformulation:', error);
			} finally {
				this.loadingReformulation = false;

				if (process.env.VUE_APP_MIXPANEL === 'production') {
					this.$mixpanel.track('Reformulate text in assistant');
				}
			}
		},

		copyToClipboard(text) {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					this.snackbarCopied = true; // Affiche la notification
				})
				.catch((err) => {
					console.error('Erreur lors de la copie dans le presse-papier :', err);
				});
		},
		async submit() {
			this.loading = true;
			let textForApi = '';

			const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
			window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

			try {
				this.checking = [];
				this.allTextError = [];
				let issueMessage = '';
				let textError = '';

				delete this.$http.defaults.headers.common['x-auth-token'];

				// Récupère le contenu HTML original et nettoie les styles inline
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = this.$refs.text.innerHTML;

				// Supprime tous les attributs style des éléments
				tempDiv.querySelectorAll('*').forEach((element) => {
					element.removeAttribute('style');
				});

				const originalHtml = tempDiv.innerHTML;

				// Préserve la structure exacte du texte pour l'API
				textForApi = originalHtml
					.replace(/<div><br><\/div>/gi, '\n')
					.replace(/<div>/gi, '')
					.replace(/<\/div>/gi, '\n')
					.replace(/<br\s*\/?>/gi, '\n')
					.replace(/&nbsp;/g, ' ')
					.replace(/<\/?[^>]+(>|$)/g, '')
					.replace(/\n\s*\n/g, '\n\n')
					.replace(/([.!?])([\wÀ-ÿ])/g, '$1 $2')
					.trim();

				// Vérifie si le texte est vide
				if (!textForApi.trim()) {
					this.loading = false;
					return (this.snackbarErrorTextEmpty = true);
				}

				// Requête vers LanguageTool
				const requestCheck = await this.$http.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						text: textForApi,
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
					},
				});

				if (requestCheck.data.matches.length == 0) {
					this.noError = true;
					this.$confetti.start({
						particleCount: 100,
						spread: 70,
					});
					this.text = originalHtml;
					return;
				}

				// Copie du HTML original pour les modifications
				let correctedHtml = originalHtml;

				// Traitement des erreurs
				requestCheck.data.matches.forEach((element) => {
					issueMessage = element.rule.issueType === 'misspelling' ? element.rule.description : element.message;

					let positionInText = element.offset;
					textError = textForApi.substring(positionInText, positionInText + element.context.length);

					this.allTextError.push({
						text: textError,
						type: element.rule.issueType,
					});

					let filterBestRemplacement = element.replacements.filter((remplacement) => {
						if (remplacement.value.length > 2) {
							return remplacement.value.substring(0, 3) === textError.substring(0, 3);
						}
						return true;
					});

					this.checking.push({
						issueType: element.rule.issueType,
						replacements: filterBestRemplacement,
						message: issueMessage,
					});
				});

				// Application des corrections en préservant la structure HTML
				this.allTextError.forEach((element, index) => {
					const errorSpan = `<span class="errorText ${element.type}" id="textwrong-${index}">${element.text}</span>`;
					correctedHtml = correctedHtml.replace(element.text, errorSpan);
				});

				// Met à jour le texte avec les corrections tout en préservant la structure
				this.text = correctedHtml;
			} catch (error) {
				console.error('Erreur lors de la correction:', error);
				this.error = true;
			} finally {
				this.loading = false;
				if (textForApi && textForApi.trim()) {
					this.snackbar = true;
				}
				this.showRating = true;
				this.btnExample = false;
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('Check text in assistant');
				}
			}
		},
		limitArray(array) {
			return array.slice(0, 5);
		},
		highlightError(indexTextError) {
			document.getElementById('textwrong-' + indexTextError).className = 'highlight';
		},
		initColorError(indexTextError) {
			let typeError = this.allTextError[indexTextError].type;
			document.getElementById('textwrong-' + indexTextError).className = 'errorText ' + typeError;
		},
		async registerText() {
			const ratingAssistant = await this.$store.dispatch('assistant/postRating', {
				formationId: this.formationInfos._id,
				rating: this.rating,
				text: this.text,
			});
			if (ratingAssistant.status == 200) {
				this.showConfirmation = true;
			}
		},
		// async getRating() {
		// 	const ratingAssistant = await this.$store.dispatch('assistant/postRating', {
		// 		formationId: this.formationInfos._id,
		// 		rating: this.rating,
		// 		text: this.text,
		// 	});
		// 	if (ratingAssistant.status == 200) {
		// 		this.showRating = false;
		// 		this.rating = 0;
		// 	}
		// },

		async submitExample() {
			//prevent double click error
			this.loading = true;

			//add cookie to remove example btn
			const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
			window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

			var text =
				'Écrivez ou collez votre texte ici pour le faire vérifier en continue. Les erreurs seront soulignés de différentes couleurs : les erreurs d’orthografe en rouge et les erreurs grammaticaux en jaune. Les problèmes de style, comme par exemple les pléonasmes, seront marqués en bleu dans vos textes. Le saviez vous ? OrthographIQ vous propose des synonymes lorsque vous double-cliquez sur un mot . Découvrez l’intégralité de ses fonctionnalités, parfoi inattendues, comme la vérification des date. Par exemple, le mercredi 28 août 2024 était en fait un vendredi !';

			try {
				this.checking = [];
				this.allTextError = [];
				let issueMessage = '';
				let textError = '';

				delete this.$http.defaults.headers.common['x-auth-token'];

				//Get value of div
				this.text = text;
				//Replace all html tags
				this.text = this.text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
				//Request to check errors
				const requestCheck = await this.$http.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						text: this.text,
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
					},
				});
				//if no error pop message no error
				if (requestCheck.data.matches.length == 0) {
					this.noError = true;
				}
				requestCheck.data.matches.map((element) => {
					if (element.rule.issueType == 'misspelling') {
						issueMessage = element.rule.description;
					} else {
						issueMessage = element.message;
					}
					let positionInText = element.offset;
					textError = this.text.substring(positionInText, positionInText + element.context.length);
					this.allTextError.push({ text: textError, type: element.rule.issueType });
					let filterBestRemplacement = element.replacements.filter((remplacement) => {
						if (remplacement.value.length > 2) {
							if (remplacement.value.substring(0, 3) == textError.substring(0, 3)) {
								return remplacement;
							}
						} else {
							return remplacement;
						}
					});
					this.checking.push({
						issueType: element.rule.issueType,
						replacements: filterBestRemplacement,
						message: issueMessage,
					});
				});
				this.allTextError.map((element, index) => {
					this.text = this.text.replace(
						element.text,
						'<span class="errorText ' + element.type + '" id=textwrong-' + index + '>' + element.text + '</span>'
					);
				});
			} catch (error) {
				this.error = true;
			}
			this.loading = false;
			this.btnExample = false;
			await this.reformulateText();

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('Check text in assistant');
			}
		},
		closeNoErrorModal() {
			this.noError = false;
			this.$confetti.stop();
			this.snackbar = false;
		},
	},
};
</script>

<style lang="scss">
.fullAssistantContainer {
	margin-top: 20px;
}
.assistant-link {
	cursor: pointer;
	transition: color 0.5s;
}
.assistant-link:hover {
	color: black !important;
}
.searching-image-container {
	text-align: center;
}
.searching-image {
	max-width: 100%;
}
.container-textarea {
	position: -webkit-sticky;
	position: sticky;
	top: 20px;
	.divEditionText {
		height: 20rem;
		&:focus {
			outline: none;
		}
	}
}
.suggestion-topic {
	text-transform: uppercase;
}
.assistant-icon {
	width: 8%;
	margin-right: 10px;
}
.suggestion-container {
	padding: 20px 20px 20px 20px;
}
.suggestion-container:hover {
	background-color: rgba(255, 0, 0, 0.06);
}
.container-chip {
	margin-bottom: 1.5rem;
}
.suggestion-chips {
	margin-top: 0.5rem;
	cursor: pointer;
	color: white !important;
	font-weight: bold;
	margin-left: 12px;
}
.menuSelected {
	color: grey !important;
	// border-bottom: 1px solid grey;
}
.align-items {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.assistant-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.v-input__slot {
	position: initial !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
	box-shadow: initial;
	-webkit-box-shadow: initial;
}
.errorText {
	&.uncategorized {
		background: rgb(201, 205, 255);
	}
	&.misspelling {
		background: rgb(255 228 201);
	}
	&.style {
		background: #e3c977;
	}
	&.typographical {
		background: rgba(255, 0, 0, 0.2);
	}
}
.iconError {
	&.uncategorized {
		color: rgb(201, 205, 255);
	}
	&.misspelling {
		color: rgb(255 228 201);
	}
	&.style {
		color: #e3c977;
	}
	&.typographical {
		color: rgba(255, 0, 0, 0.2);
	}
}
.highlight {
	background: rgba(255, 0, 0, 0.6);
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	padding: initial;
}
.v-input {
	font-size: 20px !important;
}
@media screen and (max-width: 960px) {
	.fullAssistantContainer {
		margin-right: 0; // Override vuetify .row that makes page overflow y
		margin-left: 0;
	}
	.actionBtnContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.actionBtnAssistant {
		width: 100%;
		margin-bottom: 10px;
	}
	.searching-image-container {
		display: none;
	}
	// .ratingStars {
	// 	display: none !important;
	// }
}
</style>
