<template>
	<div class="main-parameter-div mb30">
		<h1 class="title-settings mb30 mt20">Modifier mes préférences 🎛️</h1>
		<div class="divider-parameter-div">
			<div class="sub-parameter-div">
				<div class="input-div-user">
					<div class="subSectionTitle">
						<span>Vos informations</span>
					</div>
					<v-text-field
						v-model="userForm.first_name"
						class="input-parameters"
						outlined
						label="Prénom"
						color="black"
						:rules="[rules.required]"
						:error-messages="errors.first_name"
					></v-text-field>
					<v-text-field
						v-model="userForm.last_name"
						class="input-parameters"
						outlined
						label="Nom"
						color="black"
						:rules="[rules.required]"
						:error-messages="errors.last_name"
					></v-text-field>
					<v-text-field
						v-model="userForm.email"
						class="input-parameters"
						outlined
						label="Email"
						color="black"
						:rules="[rules.required, rules.email]"
						:error-messages="errors.email"
					></v-text-field>
					<div class="d-flex justify-end">
						<v-btn
							dark
							:loading="loading"
							:disabled="!isFormValid || loading || !hasChanges"
							color="#15c39a"
							@click="handleUpdateUserInfo"
						>
							<v-icon left>mdi-content-save</v-icon>
							Sauvegarder
						</v-btn>
					</div>
				</div>

				<div class="input-div-sound mb30">
					<div class="subSectionTitle">
						<span>Interactions</span>
					</div>
					<v-switch
						v-model="switchSound"
						@change="handleSwitchChange"
						color="black"
						:label="`Activer le son durant les exercices : ${seeValueSwitch()}`"
					></v-switch>
					<AllowPushNotificationSetting />
				</div>
			</div>
			<div class="sub-parameter-div">
				<div class="container-infos shadow-blue-not-openned">
					<div class="subSectionTitleWithoutborder">
						<span>📱 Télécharger l'application</span>
					</div>
					<a
						target="_blank"
						href="https://play.google.com/store/apps/details?id=com.orthographiq.app.twa&pcampaignid=web_share"
						><p>Android : Google Play Store</p></a
					>
					<a target="_blank" href="https://youtu.be/tzZ8c_PejNw?si=bHxkKUt3UKLLihLl&t=27"
						><p>iPhone : comment ajouter sur l'écran d'accueil ?</p></a
					>
				</div>
				<div class="container-infos shadow-blue-not-openned">
					<div class="subSectionTitleWithoutborder">
						<span>⚖️ RGPD et données personnelles</span>
					</div>
					<a target="_blank" href="https://www.orthographiq.com/donnees-personnelles?utm_source=app"
						><p>Voir la politique de protection des données personnelles</p></a
					>
				</div>
				<div class="container-infos shadow-blue-not-openned">
					<div class="subSectionTitleWithoutborder">
						<span>❓ Foire aux questions</span>
					</div>
					<a target="_blank" href="https://orthographiq.crisp.help/fr?from=app-espace-perso"
						><span>Accéder à la FAQ pour avoir les réponses à toutes tes questions</span></a
					>
				</div>
				<!-- <div
					v-if="profileInfos.entreprise_id && profileInfos.entreprise_id._id != '5f6c994eac759a00173cef92'"
					class="container-infos shadow-blue-not-openned"
				>
					<div class="subSectionTitleWithoutborder">
						<span>🏖️ Je pars en vacances</span>
					</div>
					<a target="_blank" href="https://forms.gle/MBQWJDzQzEEKUN2EA"><span>Mettre en pause ma formation</span></a>
				</div> -->
			</div>
		</div>
		<div class="mt20">
			<a v-if="formationProgress == null" href="/dashboard/profile">
				<v-btn class="btn-back-parameter">Retour à mon espace personnel</v-btn>
			</a>
			<v-btn v-else class="btn-back-parameter" @click="handleBackToDashboardBtn">Retour à mon espace personnel</v-btn>
		</div>

		<v-snackbar v-model="snackbar.show" color="#15c39a" :timeout="3000" left>
			{{ snackbar.text }}
		</v-snackbar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import soundCorrect from '@/assets/sound/goodAnswer.mp3';
import AllowPushNotificationSetting from './AllowPushNotificationSetting.vue';
import axios from 'axios';

export default {
	name: 'ListParameter',
	components: { AllowPushNotificationSetting },
	data() {
		return {
			storageKeySound: 'acceptSound',
			switchSound: true,
			loading: false,
			initialValues: {
				first_name: '',
				last_name: '',
				email: '',
			},
			userForm: {
				first_name: '',
				last_name: '',
				email: '',
			},
			errors: {
				first_name: '',
				last_name: '',
				email: '',
			},
			rules: {
				required: (v) => !!v || 'Ce champ est requis',
				email: (v) => {
					const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					return pattern.test(v) || 'Email invalide';
				},
			},
			snackbar: {
				show: false,
				text: '',
			},
		};
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos', formationProgress: 'formationProgress' }),
		isFormValid() {
			return (
				this.userForm.first_name &&
				this.userForm.last_name &&
				this.userForm.email &&
				this.rules.email(this.userForm.email) === true
			);
		},
		hasChanges() {
			return (
				this.userForm.first_name !== this.initialValues.first_name ||
				this.userForm.last_name !== this.initialValues.last_name ||
				this.userForm.email !== this.initialValues.email
			);
		},
	},
	async mounted() {
		const cookie = window.localStorage.getItem(this.storageKeySound);
		if (cookie == null) this.switchSound = true;
		else this.switchSound = false;

		await this.$store.dispatch('profile/getUserProfile');
		this.initializeForm();
	},
	methods: {
		initializeForm() {
			// Copier les valeurs initiales
			this.initialValues = {
				first_name: this.profileInfos.first_name,
				last_name: this.profileInfos.last_name,
				email: this.profileInfos.email,
			};
			// Initialiser le formulaire avec les mêmes valeurs
			this.userForm = {
				first_name: this.profileInfos.first_name,
				last_name: this.profileInfos.last_name,
				email: this.profileInfos.email,
			};
		},
		async handleUpdateUserInfo() {
			try {
				this.loading = true;
				this.clearErrors();

				await axios.put('/api/users/settings/information', this.userForm);
				await this.$store.dispatch('profile/getUserProfile');

				// Mettre à jour les valeurs initiales après une sauvegarde réussie
				this.initialValues = {
					first_name: this.userForm.first_name,
					last_name: this.userForm.last_name,
					email: this.userForm.email,
				};

				this.snackbar = {
					show: true,
					text: 'Vos informations ont été mises à jour avec succès',
					color: 'success',
				};
			} catch (error) {
				const errorMessage =
					error.response && error.response.data && error.response.data.message
						? error.response.data.message
						: 'Une erreur est survenue lors de la mise à jour de vos informations';

				if (error.response && error.response.status === 400) {
					this.errors.email = errorMessage;
				}

				this.snackbar = {
					show: true,
					text: errorMessage,
					color: 'error',
				};
			} finally {
				this.loading = false;
			}
		},
		clearErrors() {
			this.errors = {
				first_name: '',
				last_name: '',
				email: '',
			};
		},
		handleSwitchChange() {
			this.checkValueSwitch();
		},
		checkValueSwitch() {
			var audioCorrect = new Audio(soundCorrect);
			if (this.switchSound == false) {
				const newCookie = { hideUntil: moment().add(100, 'days').unix() };
				window.localStorage.setItem(this.storageKeySound, JSON.stringify(newCookie));
			} else {
				audioCorrect.play();
				window.localStorage.removeItem(this.storageKeySound);
			}
		},
		seeValueSwitch() {
			if (this.switchSound == true) {
				return 'oui';
			} else {
				return 'non';
			}
		},
		handleBackToDashboardBtn() {
			if (this.formationProgress && this.formationProgress.formation.for_session === true) {
				this.$router.push({
					name: 'Redaction Dashboard Index',
					params: { formationId: this.formationProgress.formation._id },
				});
			} else {
				this.$router.push({ name: 'Profile Index' });
			}
		},
	},
};
</script>

<style scoped>
.title-settings {
	text-align: center;
}
.main-parameter-div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.divider-parameter-div {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.sub-parameter-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	padding-left: 20px;
	padding-right: 20px;
}
.container-infos {
	width: 100%;
	height: auto;
	border-radius: 8px;
	margin-bottom: 20px;
	padding: 20px 20px 20px 20px;
}
.subSectionTitle {
	font-weight: bold;
	margin-bottom: 30px;
	border-bottom: 1px solid grey;
	color: grey;
}
.subSectionTitleWithoutborder {
	font-weight: bold;
	margin-bottom: 30px;
	color: grey;
}
.input-div-user {
	width: 100%;
}
.input-div-sound {
	width: 100%;
}
.btn-back-parameter {
	background-color: black !important;
	color: white;
}
.input-parameters {
	margin-bottom: 16px;
}

@media screen and (max-width: 960px) {
	.sub-parameter-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	.divider-parameter-div {
		display: flex;
		flex-direction: column;
	}

	.container-infos {
		width: 100%;
		height: auto;
		border-radius: 8px;
		margin-bottom: 20px;
		padding: 20px 20px 20px 20px;
	}
}
</style>
