<template>
	<div class="qualified-weekly-leaderboard">
		<v-container fluid v-if="loading">
			<LeaderBoardRowSkeleton />
		</v-container>
		<div v-else>
			<h2 id="WeeklyQualifiedTitle" class="ortho-title text-center pb-5">{{ qualifiedTitle }}</h2>

			<!-- Ajout du bouton de switch -->
			<div class="text-center mb-4">
				<div class="cursor-pointer" @click="toggleUserScope">
					<span class="ortho-bold fs12 ortho-text-gray cta" style="color: rgba(0, 0, 0, 0.54)">{{
						showAllUsers ? 'VOIR MA LIGUE' : 'VOIR TOUS LES UTILISATEURS'
					}}</span>
					<v-icon class="mr3 arrow-right" color="#979FA9" small>mdi-arrow-down</v-icon>
				</div>
			</div>

			<div class="mb-3 divider"></div>

			<div id="LeaderboardTable">
				<template v-for="item in leaderboard">
					<MyPositionRow
						:key="item.formationId"
						v-if="item != null && item.formationId === myFormationId"
						:name="capitalizeName(item.userName)"
						:score="item.totalPoints"
						:position="item.position"
					/>
					<OtherUserRow
						:key="item.formationId"
						v-else
						:name="capitalizeName(item.userName)"
						:score="item.totalPoints"
						:position="item.position"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LeaderBoardRowSkeleton from '@/components/skeletons/LeaderBoardRowSkeleton.vue';
import OtherUserRow from './OtherUserRow';
import MyPositionRow from './MyPositionRow';
import { capitalize } from '@/utils/text.js';

export default {
	name: 'QualifiedWeeklyLeaderBoard',
	components: { LeaderBoardRowSkeleton, OtherUserRow, MyPositionRow },
	props: {
		myFormationId: String,
	},
	data() {
		return {
			loading: true,
			leaderboard: null,
			showAllUsers: false,
		};
	},
	computed: {
		...mapGetters('leaderboard', {
			weeklyLeaderboard: 'weeklyLeaderboard',
		}),

		qualifiedTitle() {
			const { position } = this.leaderboard.find((item) => item.formationId === this.myFormationId);
			if (position === 1) return 'Vous êtes classé 1er cette semaine, félicitations';
			if (position === 2) return 'Vous êtes classé 2ème cette semaine, félicitations';
			if (position === 3) return 'Vous êtes classé 3ème cette semaine, félicitations';
			return `Vous êtes classé ${position}ème cette semaine`;
		},
	},
	async created() {
		await this.fetchWeeklyLeaderboard();
	},

	methods: {
		dataExpired(timeString) {
			if (timeString == null) return true;
			const threeMinutesAgo = new Date();
			threeMinutesAgo.setMinutes(threeMinutesAgo.getMinutes() - 3);
			return new Date(timeString) < threeMinutesAgo;
		},
		async toggleUserScope() {
			try {
				this.loading = true;
				this.showAllUsers = !this.showAllUsers;
				await this.fetchWeeklyLeaderboard();
			} catch (error) {
				// Handle error silently
			} finally {
				this.loading = false;
			}
		},
		async fetchWeeklyLeaderboard() {
			try {
				const leaderboardData = await this.$store.dispatch('leaderboard/fetchWeeklyLeaderboard', {
					showAllUsers: this.showAllUsers,
				});
				this.leaderboard = leaderboardData;
			} catch (error) {
				// Handle error silently
			} finally {
				this.loading = false;
			}
		},
		capitalizeName(name) {
			return capitalize(name);
		},
	},
};
</script>

<style lang="scss" scoped>
.qualified-weekly-leaderboard {
	.divider {
		background-color: #eee;
		height: 2px;
	}
}

#LeaderboardTable {
	padding-top: 10px;
}

.scope-toggle-btn {
	font-size: 0.875rem;
	text-transform: none;
	letter-spacing: normal;
}

@media screen and (max-width: 960px) {
	#LeaderboardTable {
		padding-right: 10px;
		padding-left: 10px;
	}
	#WeeklyQualifiedTitle {
		font-size: 20px;
	}
}
</style>
