import { render, staticRenderFns } from "./ProfileModal.vue?vue&type=template&id=acbaf992&scoped=true&"
import script from "./ProfileModal.vue?vue&type=script&lang=js&"
export * from "./ProfileModal.vue?vue&type=script&lang=js&"
import style0 from "./ProfileModal.vue?vue&type=style&index=0&id=acbaf992&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbaf992",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAvatar,VBtn,VContainer,VIcon})
