<template>
	<div>
		<div class="mb-9">
			<v-col offset-md="0" md="11" cols="11" class="no-padding-progress-bar-parcours">
				<div class="mx-auto no-padding-progress-bar-parcours">
					<ParcoursProgressBar :selectingParcours="selectingParcours" />
				</div>
			</v-col>
		</div>
		<ListChapterSketelon v-if="!selectingParcours"></ListChapterSketelon>
		<ListChapters v-else :parcours="selectingParcours" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ListChapterSketelon from '../../../skeletons/ListChapterSketelon';
import ListChapters from '../chapter/ListChapters';
import ParcoursProgressBar from '@/components/progress-bar/ParcoursProgressBar.vue';

export default {
	name: 'ParcoursDetail',
	components: {
		ListChapterSketelon,
		ListChapters,
		ParcoursProgressBar,
	},
	data() {
		return {
			selectingParcours: null,
		};
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
	},
	created() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.selectingParcours = currentParcoursItem.parcours_id;
		},
	},
};
</script>

<style lang="scss" scoped>
.no-padding-progress-bar-parcours {
	padding: 0 !important;
}
</style>
