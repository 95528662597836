<template>
	<v-card
		class="mx-auto shadow-blue mb30 fill-blanks-answer-detail"
		:class="{
			pa7: $vuetify.breakpoint.smAndDown,
			pa30: $vuetify.breakpoint.mdAndUp,
		}"
		max-width="100%"
	>
		<span class="ortho-bold pb20">-{{ testTrack.position }}-</span>
		<br />
		<br />
		<span class="question-result-title ortho-bold">{{ testTrack.question }}</span>
		<br />
		<br />
		<span class="question-action">{{ testTrack.instruction }}</span>
		<br />
		<br />

		<v-row v-if="userAnswerForThisTrack && userAnswerForThisTrack.score === 100">
			<v-col>
				<div v-if="testTrack.answers.length > 1">Tes réponses :</div>
				<div v-else>Ta réponse :</div>
				<div
					v-for="(answer, index) in testTrack.answers"
					:key="index + 2"
					class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct"
				>
					{{ answer.answer }}
				</div>
				<div v-if="testTrack.explanation">
					<v-divider class="mt-3 mb-3"></v-divider>
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="text-left">
									<div v-html="testTrack.explanation"></div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>

		<v-row v-else-if="userAnswerForThisTrack && userAnswerForThisTrack.score < 100">
			<v-col>
				<div v-if="testTrack.answers.length > 1">Bonnes réponses :</div>
				<div v-else>Bonne réponse :</div>
				<div
					v-for="(answer, index) in testTrack.answers"
					:key="(index + 34) * 55"
					class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct"
				>
					{{ answer.answer }}
				</div>

				<!-- Answers user given -->
				<div v-if="testTrack.answers.length > 1">Tes réponses :</div>
				<div v-else>Ta réponse :</div>
				<template v-if="answersUserGiven[0] == null">
					<div
						v-for="(answer, index) in answersUserGiven"
						:key="(index + 71) * 11.2"
						class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct ortho-questions-answers-incorrect ortho-questions-answers-incorrect-border"
					>
						Pas de réponse
					</div>
				</template>

				<template v-else>
					<div
						v-for="(answer, index) in answersUserGiven"
						:key="(index + 62) * 3.95"
						class="ortho-bold ortho-questions-answers-cour ortho-questions-answers-correct"
						:class="{
							'ortho-questions-answers-incorrect ortho-questions-answers-incorrect-border': !isThisAnswerCorrect(
								answer,
								testTrack.answers[index]
							),
						}"
					>
						{{ answer }}
					</div>
				</template>

				<div v-if="testTrack.explanation">
					<v-divider class="mt-3 mb-3"></v-divider>
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="text-left">
									<div v-html="testTrack.explanation"></div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'FillBlanksAnswerDetail',
	props: {
		testTrack: Object,
		testScore: Object,
	},
	data() {
		return {
			answersUserGiven: null,
			userAnswerForThisTrack: null,
		};
	},
	methods: {
		findanswersUserGiven(testTrack) {
			const userAnswer = this.testScore.user_answers.find((userAnswer) => userAnswer.test_track_id === testTrack._id);
			if (userAnswer) this.userAnswerForThisTrack = userAnswer;

			this.answersUserGiven = userAnswer ? userAnswer.user_answers : null;
		},
		isThisAnswerCorrect(userAnswer, correctAnswer) {
			return userAnswer && userAnswer.trim().toLowerCase() === correctAnswer.answer.trim().toLowerCase();
		},
	},
	created() {
		this.findanswersUserGiven(this.testTrack);
	},
};
</script>
<style scoped lang="scss">
.question-result-title {
	font-size: 30px;
}
.question-action {
	color: grey;
}
@media only screen and (max-width: 600px) {
	.question-result-title {
		font-size: 20px;
	}
}

.fill-blanks-answer-detail {
	.ortho-questions-answers-cour {
		display: block;
		width: fit-content;
		min-width: 100px;
		margin: 12px auto;
	}
}
</style>
