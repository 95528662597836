<template>
	<div v-if="testTracks">
		<h2 class="mb10">Tes réponses</h2>
		<img src="@/assets/icons/arrow-down.svg" class="arrow-icon mb20" />
		<v-row v-for="(i, index) in testTracks.length" v-bind:key="i">
			<v-col xl="12" md="12" sm="12" cols="12">
				<QCMAnswerDetail
					v-if="testTracks[index].question_type === 'QCM'"
					:testTrack="testTracks[index]"
					:testScore="testScore"
				/>

				<FillBlanksAnswerDetail
					v-else-if="testTracks[index].question_type === 'Fill blanks'"
					:testTrack="testTracks[index]"
					:testScore="testScore"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import QCMAnswerDetail from './QCMAnswerDetail.vue';
import FillBlanksAnswerDetail from './FillBlanksAnswerDetail.vue';
export default {
	name: 'UserAnswersDetail',
	components: { QCMAnswerDetail, FillBlanksAnswerDetail },
	props: {
		testTracks: Array,
		testScore: Object,
	},
	mounted() {
		document.addEventListener('copy', (event) => {
			event.preventDefault();
			alert('La copie est désactivée sur cette page.');
		});
	},
};
</script>
<style scoped>
.arrow-icon {
	width: 2%;
	color: #15c39a !important;
}
</style>
