export const firebaseConfig = {
	apiKey: 'AIzaSyDDtbk-CPN3GkxGiXKZDIzMuQBTXrwvjcE',
	authDomain: 'orthographiq-290408.firebaseapp.com',
	projectId: 'orthographiq-290408',
	storageBucket: 'orthographiq-290408.firebasestorage.app',
	messagingSenderId: '982553495325',
	appId: '1:982553495325:web:f2db6793b8091a1f0183f4',
	measurementId: 'G-001D0ZCPY6',
};

export const PUBLIC_VAPID_KEY =
	'BCkrprzyl9uHF4NWgQYU16PT33_xGv-03yT0lcUMyLAfIjMa2aa1exBgnLPPLF3vV_gwfrJREJhevvNQceAAlpQ';
