<template>
	<v-card class="shadow-blue pa30 mt-5">
		<h2>Points gagnés sur le programme</h2>
		<span class="ortho-text-gray">Les points gagnés sur les notions vues durant le programme</span>
		<CategorySessionPointProgressBar :totalScore="totalScore" :score="score" />
	</v-card>
</template>
<script>
import CategorySessionPointProgressBar from '@/components/progress-bar/CategorySessionPointProgressBar.vue';
export default {
	name: 'TotalPointCard',
	components: { CategorySessionPointProgressBar },
	props: {
		totalScore: Number,
		score: Number,
	},
};
</script>
<style lang="scss" scoped></style>
